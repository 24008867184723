import {loadModal} from "../../redux/slices/hulabill-slice";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {ComponentData} from "../component-renderer/componentRenderer";
import {StructuralSectionsMap} from "../structural-sections/StructuralSectionsMap";
import {openSection} from "../structural-sections/structuralSectionsFactory";

export enum ModalType {
    DRAWER = "Drawer",
    DIALOG = "Dialog",
    DRAWER_DIALOG = "DrawerDialog",
}

export enum NavActionType {
    LINK = "Link",
    BUTTON = "Button"
}

export interface NavActionData extends ComponentData {
    "label": string,
    "rel": string,
    "variant": NavActionType,
    "data"?: any,
    "method"?: "POST" | "GET"
    "icon": string,
    "fillWidth": boolean,
    modalType: ModalType,
}

const navActions = {
    [ModalType.DRAWER]: openSection(StructuralSectionsMap.RIGHT_DRAWER),
    [ModalType.DIALOG]: openSection(StructuralSectionsMap.DIALOG),
    [ModalType.DRAWER_DIALOG]: openSection(StructuralSectionsMap.DRAWER_DIALOG)
}

export const useNavAction = () => {
    const dispatch: AppDispatch = useDispatch()

    return ({modalType, rel, data, method}: Partial<NavActionData>) => { //TODO separate action visual component from action logic
        dispatch(navActions[modalType!](rel!, method, data))
    }
}