import {AmazonCCP} from "../../amazon/AmazonCCP";
import {AgentPanel} from "./AgentPanel/AgentPanel";
import {HomeContainer} from "./Home.styles";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {accountSelectors} from "../../redux/slices/account-slice";
import {GlobalDialog} from "../../engine/structural-sections/section-components/Dialog";
import { DataGrid } from '@mui/x-data-grid';

export const Home = () =>{
    const navigate = useNavigate();
    const credentials = useSelector(accountSelectors.getCredentials)

    useEffect(() => {
        if(!credentials.idToken){
            navigate('/login')
        }
    }, [credentials])

    return <HomeContainer>
        <AmazonCCP/>
        <AgentPanel/>
    </HomeContainer>
}