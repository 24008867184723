import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";
import {StructuralSection} from "./structural-sections/structuralSectionsFactory";

export const useComponentState = (section: StructuralSection, ownPath: string) => {
    const dispatch: AppDispatch = useDispatch()

    const setPartialState = section.actions.setPartialState

    const setComponentState = (newState: any) => {
        dispatch(setPartialState({path: ownPath, value: newState}))
    }

    return [setComponentState]
}