import {FC} from "react";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import {getApi} from "../../services/api";
import {AccountSearchFormProps, AccountSearchForm} from "./AccountSearchForm";
import {PatientListData, PatientResults} from "./PatientList";
import {ComponentProps} from "../../engine/component-renderer/componentRenderer";

export interface AccountSearchData extends ComponentProps<AccountSearchData> {
    search: AccountSearchFormProps;
    postbackUrl: string;
    patientList: PatientListData
}

export const AccountSearch: FC<AccountSearchData> = ({setComponentState, search, postbackUrl, patientList, renderChild}) => {
    const [loading, setLoading] = useState(false);

    const doSearch = async (params: any) => {        
        setComponentState({
            patientList: undefined
        })

        setLoading(true);
        const patientList = await getApi().post(postbackUrl, params).then(r => r.data)

        setComponentState({
            patientList
        })
        setLoading(false);
    }

    return <>
        <AccountSearchForm form={search.form} bar={search.bar} onSearch={doSearch}/>
        {patientList && <PatientResults {...patientList} renderChild={renderChild}/>}
        {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                    <CircularProgress />
                </div>
            )}
    </>
}