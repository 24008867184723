import {FC} from "react";
import {ComponentData} from "../engine/component-renderer/componentRenderer";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import {styled} from "@mui/material";

export interface BoldTwoColumnData extends ComponentData {
    boldColumn: string;
    valueColumn: string;
}

const BoldTwoColumnRoot = styled('div')`
    display: flex;
`

const BoldContainer = styled('div')`
    font-weight: bold;
    margin-right: 32px;
`

const ValueContainer = styled('div')`
`

export const BoldTwoColumn: FC<BoldTwoColumnData> = ({boldColumn, valueColumn}) => {
    return <BoldTwoColumnRoot>
        <BoldContainer>{boldColumn}</BoldContainer>
        <ValueContainer>{valueColumn}</ValueContainer>
    </BoldTwoColumnRoot>
}
