import {FC, useState} from "react";
import {MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";
import {SectionTitle} from "./blocks/shared.styles";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export const SelectSectionDropdownHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`

export const SelectSectionDropdown = styled(Select)`
  width: 100%;
`

export interface SelectSectionData extends ComponentProps<SelectSectionData> {
    title: string;
    dropdownmenu: {
        header: string;
        menuitems: {
            item: string;
            details: ComponentData
        }[]
    }
}

export const SelectSection: FC<SelectSectionData> = ({title, dropdownmenu, renderChild}) => {
    const [selected, setSelected] = useState(0)

    const handleChange: any = (event: SelectChangeEvent<number>) => {
        
        setSelected(event.target.value as number);
    };

    return <div>
        <SectionTitle>{title}</SectionTitle>
        <SelectSectionDropdownHeader>{dropdownmenu.header}</SelectSectionDropdownHeader>
        <SelectSectionDropdown variant={'standard'} value={selected} onChange={handleChange}>
            {dropdownmenu.menuitems.map((mi, i) => <MenuItem key={i} value={i}>{mi.item}</MenuItem>)}
        </SelectSectionDropdown>
        {renderChild(dropdownmenu.menuitems[selected].details)}
    </div>
}