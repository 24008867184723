import {FC} from "react";
import {Chip, styled} from "@mui/material";
import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";

export const ChipTabContainer = styled('div')<{ shaded?: boolean, justify?: string }>`
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  ${props => !props.justify ? `
      > * {
        flex: 1
      }
  ` : ''}
  background: ${props => props.shaded ? 'rgb(238, 236, 242)' : 'transparent'};
  border-radius: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`


export const ChipTab = styled(Chip)<{ selected?: boolean }>`
  background: ${props => props.selected ? 'rgb(67,67,91)' : 'rgb(238, 236, 242)'};
  color: ${props => props.selected ? 'rgb(238,236,242)' : 'rgb(67,67,91)'};
  font-size: 14px;
  
  > svg {
    margin: 8px 0 8px 8px;
  }
`

export const DrawerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

interface HulabillProps extends ComponentProps<HulabillProps> {
    top: ComponentData[];
    bottom: ComponentData[];
}

export const Hulabill: FC<HulabillProps> = ({top, bottom, renderChild}) => {
    return <DrawerContainer>
            <div>{top.map(renderChild)}</div>
            <>{bottom.map(renderChild)}</>
        </DrawerContainer>
}