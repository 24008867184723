import {FC} from "react";

import {StatementCardContent, StatementCardRoot, StatementCardRow} from "./blocks/shared.styles";
import {StatementCardData} from "./StatementCard";

export const ServiceCard: FC<StatementCardData> = ({header, rows, footer, quickAction}) => {
    return <StatementCardRoot variant={'outlined'}>
        <StatementCardContent>
            {rows.map(r => <StatementCardRow>
                <div>{r.description}</div>
                <div>{r.amount}</div>
            </StatementCardRow>)}
        </StatementCardContent>
    </StatementCardRoot>
}