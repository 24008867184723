import React, {FC} from "react";
import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";
import {DrawerContainer} from "./Hulabill";
import {TabbedQuickActionContent} from "./TabbedQuickActions";
import {NavActionData} from "../engine/navigation/useNavAction";

interface ActionableQuickActionsContainerProps extends ComponentProps<ActionableQuickActionsContainerProps> {
    tabs: TabbedQuickActionContent[];
    actions: NavActionData[]
}

export const ActionableQuickActionsContainer: FC<ActionableQuickActionsContainerProps> = ({tabs, actions, renderChild, path}) => {
    return <DrawerContainer>
        {renderChild({
            "@type": "@TabbedQuickActions",
            path,
            tabs,
            actions
        } as ComponentData)}
        </DrawerContainer>
}