import {styled} from '@mui/system';
import {Button} from "@mui/material";

export const PaymentFormContent = styled('div')`
  max-width: 600px;
  padding: 0 24px;
  margin: auto;


`;


export const PaymentFields = styled('form')`
  margin-top: 40px;

  > div {
    margin-bottom: 40px;

    label {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PaymentConfirmationStatusMessage = styled('div')`
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
`;

export const PaymentConfirmationMessage = styled('div')`
  color: #666;
  margin-bottom: 20px;
  text-align: center;
  font-size: 11pt;
`;

export const PaymentConfirmationGroup = styled('div')`
  display: flex;
  margin-bottom: 6px;
  font-size: 11pt;
`;

export const PaymentConfirmationLabel = styled('div')`
  flex: 1;
  padding-right: 10px;
`;

export const PaymentConfirmationValue = styled('div')`
  text-align: right;
`;

export const PaymentFieldsWrapper = styled('div')`
  > div {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .MuiButton-root > div {
    width: 100%;
  }
  


`;

export const PaymentOptionButton = styled(Button)`
  font-size: 12px !important;
  margin-bottom: 10px !important;
  font-weight: normal !important;
  line-height: 14px !important;
  text-transform: none !important;

  & .MuiButton-root > div {
    width: 100%;
  }
`;

export const PaymentButton = styled('div')`

`;

export const DesktopPaymentHeader = styled('div')`

`;

export const DesktopPaymentHeaderTitle = styled('div')`

`;

export const DesktopPaymentHeaderBalance = styled('div')`

`;

export const PaymentFormContainer = styled('div')`
`;

export const PaymentActivityContainer = styled('div')`
  max-width: 700px;
  margin: 0 auto;
  
`;

export const PaymentActivityHeader = styled('div')`
  font-weight: bold;
  padding: 10px 0;
`;

export const PaymentActivityMethod = styled('div')`
  font-size: 12px;
  color: #666;
`;

export const PaymentPlansTabContainer = styled('div')`
  margin-bottom: 10px;
`

export const PaymentActivityListItem = styled('div')`
  &:last-child {
    border-bottom: solid 1px #ddd;
  }

  display: flex;
  flex-direction: row;
  border-top: solid 1px #ddd;
  padding: 20px 40px;
  
`