import React, {FC} from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";

import {ComponentData} from "../engine/component-renderer/componentRenderer";

export interface NotificationMenuData extends ComponentData {
    icon: string;
    notifications: ComponentData[];
}

export const NotificationMenu: FC<NotificationMenuData> = ({}) =>{
    return <NotificationsIcon/>
}