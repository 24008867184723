import {FC} from "react";
import isEmpty from "lodash/isEmpty";
import {ExpandIconPlaceholder, ExpansionTableCard, TableCell, TableRow} from "./blocks/shared.styles";
import {getColumnProportion} from "./blocks/shared.utils";
import {styled} from "@mui/material";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export const ExpansionTableHeaderColumns = styled(TableRow)`
  padding-bottom: 8px;
`

export interface ExpansionTableData extends ComponentProps<ExpansionTableData> {
    headers: string[];
    rows: ComponentData[];
}

export const ExpansionTable: FC<ExpansionTableData & {nested?: boolean}> = ({nested, headers, rows, renderChild}) => {
    return <>
        {!isEmpty(headers.join('')) && <ExpansionTableHeaderColumns>
            {headers.map((h, i) => <TableCell flexProportion={getColumnProportion(nested? 'nested': 'root', i)}>{h}</TableCell> )}
          <ExpandIconPlaceholder/>
        </ExpansionTableHeaderColumns>}
        <ExpansionTableCard variant={'outlined'}>
            {rows.map(renderChild)}
        </ExpansionTableCard>
    </>
}