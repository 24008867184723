import {FC, useEffect, useState} from "react";
import {ChipTab, ChipTabContainer} from "./Hulabill";
import {getApi} from "../services/api";
import {useGlobalLinks} from "../engine/useGlobalLinks";
import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";
import {NavActionData} from "../engine/navigation/useNavAction";
import {styled} from "@mui/system";
import {DRAWER_SIDE_PADDING} from "../engine/structural-sections/section-components/styles";
import {CenteredCircularProgress} from "./blocks/CenteredCircularProgress/CenteredCircularProgress";

const TabContentSection = styled('div')`
  overflow: auto;
  margin: 16px 0;
  height: calc(100% - 96px);
`

const ActionSection = styled('div')`
  position: absolute;
  bottom: 32px;
  width: calc(100% - 2 * ${DRAWER_SIDE_PADDING});
`

export type TabbedQuickActionContent = {
    rel: string;
    id: string;
    label: string,
    content: ComponentData[]
    href: string,
    icon?: string
    path: string,
};

export interface TabbedQuickActionsProps extends ComponentProps<TabbedQuickActionsProps> {
    tabs: TabbedQuickActionContent[],
    actions?: NavActionData[]
}

export const TabbedQuickActions: FC<TabbedQuickActionsProps> = ({tabs, actions, renderChild, mutateComponentState}) => {
    const [selected, setSelected] = useState<number>(0)
    const {getGlobalLink, addGlobalLinks} = useGlobalLinks()
    const [loading, setLoading] = useState(false);

    const loadTab = async () => {
        setLoading(true);
        const url = getGlobalLink(tabs[selected].rel);
        const response = await getApi().get(url).then(r => r.data);
        if(response.links){
            addGlobalLinks(response.links)
        }
        const tabContent = Array.isArray(response.current) ? response.current : [response]
        mutateComponentState(state => {
            state.tabs[selected].content = tabContent
        })
        setLoading(false);
    }

    useEffect(() => {
        loadTab()
    }, [selected]);

    return <>
        <ChipTabContainer shaded>
            {tabs && tabs.map((t, i) =>
                <ChipTab selected={i === selected} label={t.label} onClick={() => setSelected(i)}/>
            )}
        </ChipTabContainer>
        {loading ? <TabContentSection>
            <CenteredCircularProgress />
        </TabContentSection> :
            <TabContentSection>
                <>{tabs && tabs[selected]?.content && tabs[selected]?.content.map(renderChild)}</>
            </TabContentSection>
        }

        {actions && <ActionSection>
            {actions[selected]["@type"] && renderChild(actions[selected])}
        </ActionSection>}
    </>
}