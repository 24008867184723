import {GridColDef, GridPaginationModel, GridRowSelectionModel, useGridApiRef} from '@mui/x-data-grid-premium';
import React, {FC, useEffect, useState} from "react";
import {ConstraintDefinition, ConstraintPills, GridManagementPanel} from "./GridManagementPanel";
import {GridViewGroup, useGridViewManager} from "./GridViewModel";
import {ComponentData, ComponentProps} from "../../engine/component-renderer/componentRenderer";
import {EnhancedGridRoot, GridContent, StyledDataGrid} from "./EnhancedDataGrid.styles";
import {DataSource} from '../../services/data-source';
import {GridHeader} from "./GridHeader";
import {EnhancedGridToolbar} from "./EnhancedGridToolbar";
import {omit} from "lodash";
import isEmpty from "lodash/isEmpty";

export interface GridGroupDefinition {
    groupingField: string,
    leafField: string
}

export interface PaginationConfig {
    pageSize: number;
    rowCount: number; //total number of elements
}

export interface Pagination extends PaginationConfig{
    page: number
}

export interface DataGridContainerProps extends ComponentProps<DataGridContainerProps> {
    title: string,
    dataSourceUrl: string;
    constraints: ConstraintDefinition[],
    customSearch?: ComponentData,
    savedViews: {
        groups: GridViewGroup[],
        saveViewPostbackUrl: string
    },
    groupDefinition?: GridGroupDefinition,//TODo re-add or remove
}

export interface DataGridProps extends ComponentProps<DataGridProps> {
    columns: GridColDef[],
    rows: any[]
    pagination: Pagination
}

function getBaseColumns(grid: any, computedColumns: any) {
    let baseColumns = grid?.columns || [];
    if(!computedColumns){
        return baseColumns;
    } else {
        return baseColumns.map((bc: any) => ({...bc, previousWidth: computedColumns.find((cc: any) => cc.field === bc.field)?.computedWidth }))
    }
}

export const DataGridContainer: FC<DataGridContainerProps> = ({
                                                                  title,
                                                                  dataSourceUrl,
                                                                  constraints,
                                                                  customSearch,
                                                                  savedViews,
                                                                  renderChild
                                                              }) => {
    const [grid, setGrid] = useState<any>()
    const apiRef = useGridApiRef();
    console.log('alcols',apiRef.current?.getAllColumns && apiRef.current?.getAllColumns())
    const {
        viewGroups,
        selectViewById,
        duplicateCurrentView,
        saveCurrentDraft,
        getters: {
            getCurrentSortModel,
            getCurrentView,
            getCurrentColumns,
            getCurrentVisibilityModel
        },
        mutators: {
            changeSortModel,
            changeColumnOrder,
            changeVisibilityModel,
            createDraftFromConstraint,
            changeColumnSize,
            changeTitle,
            changeCustomSearch
        }
    } = useGridViewManager(savedViews.groups,
        getBaseColumns(grid, apiRef.current?.getAllColumns && apiRef.current?.getAllColumns())
        , constraints[0])
    const dataSource = new DataSource(dataSourceUrl)



    useEffect(() => {
        let dataGrid = dataSource.getDataGrid(getCurrentView().constraint.constraint, getCurrentView().customSearch);
        setGrid(dataGrid)
    }, [getCurrentView().constraint, getCurrentView().customSearch]);

    function getForm() {
        return !isEmpty(omit(getCurrentView().customSearch, 'path')) ?
            omit(getCurrentView().customSearch, 'path') :
            // @ts-ignore
            customSearch['form']
    }

    function canSave() {
        return getCurrentView().prototype && getCurrentView().prototype?.id !== getCurrentView().constraint?.id;
    }

    return <EnhancedGridRoot>
        <GridManagementPanel
            viewGroups={viewGroups}
            onSavedViewClick={v => selectViewById(v.id)}
            selectedView={getCurrentView()}
        >
            <ConstraintPills
                constraintDefs={constraints}
                currentConstraintDef={getCurrentView().prototype && getCurrentView().constraint}
                onConstraintChange={createDraftFromConstraint}
            />
        </GridManagementPanel>
        <GridContent>
            <GridHeader currentView={getCurrentView()} onViewNameChange={changeTitle}/>
            <div>
                {customSearch && renderChild(customSearch, {
                    onSearch: changeCustomSearch,
                    form: getForm()
                })}
            </div>
            {grid && <StyledDataGrid
              apiRef={apiRef}
              disableMultipleColumnsSorting
              filterMode="server"
              pagination
              sortModel={getCurrentSortModel()}
              onSortModelChange={changeSortModel}
              onColumnOrderChange={changeColumnOrder}
              onColumnVisibilityModelChange={changeVisibilityModel}
              columnVisibilityModel={getCurrentVisibilityModel()}
              onColumnWidthChange={changeColumnSize}
              localeText={{
                  toolbarColumns: "",
                  toolbarFilters: "",
                  toolbarDensity: "",
                  toolbarExport: ""
              }}
              rows={grid.rows}
              columns={getCurrentColumns()}
              disableColumnMenu={true}
              slots={{
                  toolbar: () => <EnhancedGridToolbar
                      showQuickSearch={!customSearch}
                      onDelete={() => {
                      }}
                      onDuplicateCurrent={duplicateCurrentView}
                      onSaveCurrent={canSave() ? saveCurrentDraft : undefined}
                  />,
              }}
            />}
        </GridContent>
    </EnhancedGridRoot>
}

/*TODOs
- Row selection
- Show snackbar
- Add grouping
- Row deletion
 */
