import {createSelector, createSlice} from '@reduxjs/toolkit'
import {AppDispatch, GetState} from "../store";
import {getHulabillSlice} from "../base-selectors";
import {AxiosError} from 'axios';
import {getApi} from "../../services/api";
import {addPath} from "../../utils/addPath";
import {cloneDeep, get} from 'lodash';
import {loadStepper} from "./stepper-slice";
import {addGlobalLinks, navSelectors} from './nav-slice';
import {GroupedFormsData} from "../../components/GroupedForms";
import {HeaderedContainerData} from "../../components/HeaderedContainer";
import {Link} from "../../components/OneTimePayment";
import {ComponentData} from "../../engine/component-renderer/componentRenderer";

export type LinkRel = {
    rel: string,
    href: string
};

export interface DrawerBase extends ComponentData {
    top: ComponentData[];
    bottom: ComponentData[];
    links: LinkRel[]
}

export interface HulaBillState extends DrawerBase {
    modal: {
        open: boolean,
        content?: HeaderedContainerData;
    }
    top: ComponentData[];
    bottom: ComponentData[];
    links: LinkRel[]
}

const initialState: Partial<HulaBillState> = {
    modal: {
        open: false
    }
}

export const hulabillSlice = createSlice({
    name: 'hulabill',
    initialState,
    reducers: {
        setHulabillBase: (state: Partial<HulaBillState>, action) => {
            state.top = action.payload.top;
            state.bottom = action.payload.bottom;
            state.links = action.payload.links;
            addPath(state)
        },
        setTabContent: (state: Partial<HulaBillState>, {payload: {tabPath, content, links}}) => {
            const currentTab = get(state, tabPath)
            currentTab!.content = content
            if (links) {
                state.links = (state.links || []).concat(links.filter((l: Link) => l.rel !== "self"))
            }
            addPath(state)
        },
        openModal: (state: Partial<HulaBillState>, {payload: {modalContent}}) => {
            state.modal!.open = true;
            state.modal!.content = modalContent;
            addPath(state)
        },
        closeModal: (state: Partial<HulaBillState>) => {
            state.modal!.open = false;
        },
        openModalStepper: (state: Partial<HulaBillState>) => {
            state.modal!.content!.detailSliceName = 'stepper';
        },
        closeModalStepper: (state: Partial<HulaBillState>) => {
            delete state.modal!.content!.detailSliceName;
        },
        setOwnData: (state: Partial<HulaBillState>, {payload: {path, at, value}}) => {
            const currentElement = get(state, path)
            if (at) {
                currentElement[at] = value
            } else {
                for (const property in currentElement) {
                    currentElement[property] = value[property]
                }
            }
            addPath(state)
        },
        setPartialState: (state: Partial<HulaBillState>, {payload: {path, value}}) => {
            const currentElement = path ? get(state, path) : state
            for (const property in value) { //TODO merge without altering
                currentElement[property] = value[property]
            }
            addPath(state)
        }
    },
})

export const loadModal = (modalContentRel: string) => async (dispatch: AppDispatch, getState: GetState) => {
    const href = getQuickActionHref(getHulabillSlice(getState()), modalContentRel);
    const modalContent = await getApi().get(href).then(r => r.data);
    dispatch(openModal({modalContent}))
}

export const loadActivityModal = () => async (dispatch: AppDispatch, getState: GetState) => {
    const modalContent = await getApi().get('https://rtkdybh40j.execute-api.us-east-1.amazonaws.com/dev/servicedetails').then(r => r.data);
    dispatch(openModal({modalContent}))
}

export const submitGroupedForm = (parentPath: string, groupedForms: GroupedFormsData, data: any) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const response = await getApi().post(groupedForms.postbackUrl, data)
        dispatch(setOwnData({path: parentPath, value: response?.data}))
    } catch (e) {
        if (e instanceof AxiosError) {
            dispatch(setOwnData({path: parentPath, value: e.response?.data}))
        } else throw e;
    }
}

export const startPayment = (url: string, data: any) => async (dispatch: AppDispatch, getState: GetState) => {
    // const modalContent = await getApi().post(url, data).then(r => r.data);
    // console.log(modalContent);

    // dispatch(closeModal())
    // dispatch(openModal({modalContent}))

    dispatch(loadStepper(url, data))
}


const getModalFromState: (hb: Partial<HulaBillState>) => HeaderedContainerData | undefined = (hulaBillRoot) =>
    hulaBillRoot?.modal?.content

const getQuickActionHref: (hb: Partial<HulaBillState>, rel: string) => string = (hulaBillRoot, rel: string) =>
    (hulaBillRoot.links?.find(q => q.rel === rel)?.href || hulaBillRoot.modal?.content?.links?.find(q => q.rel === rel)?.href)!


//Selectors

const getModalContent = createSelector(getHulabillSlice, getModalFromState)

export const hulabillSelectors = {
     getModalContent
}


// Action creators are generated for each case reducer function
export const {setHulabillBase, setTabContent, setOwnData, openModal, closeModal, openModalStepper, closeModalStepper,setPartialState} = hulabillSlice.actions

export default hulabillSlice.reducer