import React, {useState} from 'react';
import './App.css';
import {AppDispatch} from "./redux/store";
import {useDispatch, useSelector} from 'react-redux'
import {Document, Page} from 'react-pdf';
import {styled} from "@mui/system";
import {closePdf, navSelectors} from "./redux/slices/nav-slice";
import CancelIcon from '@mui/icons-material/Cancel';

const CloseIcon = styled(CancelIcon)`
  color: white;
  position: absolute;
  right: calc(50vw - 370px);
  cursor: pointer;
`

const PDFContainer = styled('div')`
  min-width: 100vw;
  max-height: 100vh;
  overflow: scroll;
  position: absolute;
  z-index: 10000;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center; 
  
  > * {
    margin: 24px;
  }
  
  .react-pdf__Document{
    overflow: scroll;
  }

  .react-pdf__Page{
    background: transparent;
    margin-bottom: 30px;
  }

  .react-pdf__Page__annotations {
    display: none;
  }
  
  .react-pdf__Page__textContent{
    display: none;
  }
`

export const PDFViewer = () => {
    const [numPages, setNumPages] = useState<number>();
    const pdfSource = useSelector(navSelectors.getPDF)
    const dispatch: AppDispatch = useDispatch()

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    const close = () => {
        dispatch(closePdf())
    }

    return pdfSource?
        <PDFContainer onClick={close}>
            <CloseIcon onClick={close}/>
            <Document onClick={(e) => {e.stopPropagation()}}
                file={pdfSource} onLoadSuccess={onDocumentLoadSuccess}>
                {new Array(numPages).fill(0).map((_, i) => <Page pageNumber={i + 1} />)}
            </Document>
        </PDFContainer> : null
}
