import {FC} from "react";
import {SectionTitle} from "./blocks/shared.styles";


import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export interface ExpansionPanelContainerData extends ComponentProps<ExpansionPanelContainerData> {
    title: string;
    expansionPanels: ComponentData[]
}

export const ExpansionPanelContainer: FC<ExpansionPanelContainerData> = ({title, expansionPanels, renderChild}) => {
    return <div>
        {title && <SectionTitle>{title}</SectionTitle>}
        {expansionPanels && expansionPanels.map(renderChild)}
    </div>
}
