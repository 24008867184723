import {createSelector, createSlice} from '@reduxjs/toolkit'
import {AppDispatch, GetState} from "../store";
import {fetchCredentials, fetchSignInData} from "../../services/api";
import {getAccountSlice} from "../base-selectors";
import {createPKCEpair} from "../../utils/crypto";
import {baseRoutes} from "../../App";
import {APP_DOMAIN, COGNITO_AGENT_APP_CLIENT_ID, COGNITO_DOMAIN} from "../../config";
import pick from 'lodash/pick';
import fromPairs from 'lodash/fromPairs';

const CREDENTIALS_KEYS = ['idToken', 'accessToken', 'refreshToken'];
const VERIFIER_KEY = "VERIFIER";
const SIGNIN_DATA_KEY = "SIGNIN_DATA";

export const storeVerifier = (verifier: string) => localStorage.setItem(VERIFIER_KEY, verifier);
export const getVerifier = () => localStorage.getItem(VERIFIER_KEY) as string;
export const clearVerifier = () => localStorage.removeItem(VERIFIER_KEY);

type AccountState = {
    signInData: any;
    idToken: string;
    accessToken: string;
    refreshToken: string;
};

const initialState: Partial<AccountState> = fromPairs(CREDENTIALS_KEYS.map(key => [key,localStorage.getItem(key)]));

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setSignInData: (state: Partial<AccountState>, {payload}) => {
            state.signInData = payload;
            localStorage.setItem(SIGNIN_DATA_KEY, JSON.stringify(payload))
        },
        setCredentials: (state: Partial<AccountState>, {payload}) => {
            state.idToken = payload.idToken;
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
            Object.keys(payload).forEach(key => localStorage.setItem(key, payload[key]))
        },
        clearCredentials: (state: Partial<AccountState>) => {
            delete state.idToken;
            delete state.accessToken;
            delete state.refreshToken;
            CREDENTIALS_KEYS.forEach(key => localStorage.removeItem(key))
        }
    },
})

export const loadSigninData = () => async (dispatch: AppDispatch, getState: GetState) => {
    const signInData = await fetchSignInData()
    await dispatch(setSignInData(signInData))
}

export const getAuthCode = () => async (dispatch: AppDispatch, getState: GetState) => {
    await dispatch(loadSigninData())
    const {cognito} = getSignInData(getState())
    const {challenge, verifier} = await createPKCEpair()
    storeVerifier(verifier)
    window.location.href = cognito.authorizeEndpoint + '?' +
        `identity_provider=${cognito.identityProvider}&` +
        `redirect_uri=${APP_DOMAIN}${baseRoutes.LoginSuccess.path}&` +
        `response_type=CODE&` +
        `code_challenge_method=S256&` +
        `code_challenge=${challenge}&` +
        `client_id=${cognito.clientId}&` +
        `scope=email openid phone`
}

export const loadCredentials = () => async (dispatch: AppDispatch, getState: GetState) => {
    const codeVerifier = getVerifier()
    const {cognito} = getSignInData(getState())
    const authCode = window.location.search.slice(window.location.search.indexOf('code=') + 5)
    const credentials = await fetchCredentials(cognito)(authCode, codeVerifier).catch(() => ({})) //Not sure why first time this fails, but then retries and works
    clearVerifier()
    dispatch(setCredentials({
        idToken: credentials.id_token,
        accessToken: credentials.access_token,
        refreshToken: credentials.refresh_token
    }))
}

export const logout = () => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(clearCredentials())
    window.location.href = `${COGNITO_DOMAIN}/logout?` +
        `logout_uri=${APP_DOMAIN}${baseRoutes.Home.path}&` +
        `client_id=${COGNITO_AGENT_APP_CLIENT_ID}`
}


//Selectors
const getCredentials = createSelector(getAccountSlice, acctData =>  pick(acctData, CREDENTIALS_KEYS))

const getSignInData = createSelector(getAccountSlice, acctData =>  acctData.signInData || JSON.parse(localStorage.getItem(SIGNIN_DATA_KEY) || "{}"))

export const accountSelectors = {
    getCredentials, getSignInData
}

// Action creators are generated for each case reducer function
export const {setCredentials, clearCredentials, setSignInData} = accountSlice.actions

export default accountSlice.reducer