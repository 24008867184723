import React, {FC} from "react";
import AppsIcon from "@mui/icons-material/Apps";


import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";

export interface App {
    label: string;
    src: string;
    url: string;
}

export interface AppSelectorData extends ComponentProps<AppSelectorData> {
    icon: string;
    apps: App[];
}

export const AppSelector: FC<AppSelectorData> = ({}) =>{
    return <AppsIcon/>
}