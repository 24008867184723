//Taken from https://stackoverflow.com/a/75906013
import React, {FC} from 'react'
import * as Icons from '@mui/icons-material'

export type IconName = keyof typeof Icons
export type IconProps = {
    iconName: string
} & {
    [propName: string]: any
}

export const MuiGenericIcon: FC<IconProps> = ({iconName, ...otherProps}) => {
    const Icon = Icons[iconName as IconName]
    return <Icon {...otherProps}/>
}