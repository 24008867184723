import {FC, useState} from "react";
import isEmpty from "lodash/isEmpty";
import {
    ExpandIcon,
    ExpandIconPlaceholder,
    ExpansionDetailsContainer,
    ExpansionRowColumns,
    ExpansionRowContainer,
    ExpansionRowContent,
    ExpansionTableCard,
    TableCell
} from "./blocks/shared.styles";
import {styled} from "@mui/material";
import {NavActionData} from "../engine/navigation/useNavAction";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export const ServiceExpansionRowSummary = styled('div')`
  margin: 4px 16px 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6)
`

export interface ServiceExpansionCardData extends ComponentProps<ServiceExpansionCardData> {
    date: string,
    description?: string;
    patient?: string, //TODo revise this structure
    type?: string,
    amount: string,
    summary: string,
    details: ComponentData,
    actions?: {
        "key": string,
        "action": NavActionData
    }
}

export const ServiceExpansionCard: FC<ServiceExpansionCardData> = ({date,description,  patient, type, amount, summary, details, renderChild}) => {
    const [open, setOpen] = useState(false)

    return <ExpansionTableCard variant={'outlined'}>
        <ExpansionRowContainer>
            <ExpansionRowContent>
                <ExpansionRowColumns>
                    <TableCell flexProportion={1}>{renderChild(date)}</TableCell>
                    <TableCell flexProportion={3}>{renderChild(description) || renderChild(type)}</TableCell>
                    <TableCell flexProportion={1}>{renderChild(amount)}</TableCell>
                    {isEmpty(details) ? <ExpandIconPlaceholder/> :
                        <ExpandIcon open={open} onClick={() => setOpen(o => !o)}/>}
                </ExpansionRowColumns>
                {summary && <ServiceExpansionRowSummary>{renderChild(summary)}</ServiceExpansionRowSummary>}
            </ExpansionRowContent>
            {details &&
              <ExpansionDetailsContainer open={open}>
                  {renderChild(details)}
              </ExpansionDetailsContainer>}
        </ExpansionRowContainer>
    </ExpansionTableCard>
}
