import {Card, styled} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const leftIconWidth = '48px'

export const IconDescriptionExpansionCardContainer = styled(Card)`
  border-radius: 16px;
  padding: 16px;
  margin: 16px 0;
`

export const UnexpandedContent = styled('div')`
  display: flex;
`

export const ExpandedContent = styled('div')`
  margin-left: ${leftIconWidth};
  margin-top: 8px;
`

export const IconSection = styled('div')`
  font-size: 20px;
  display: flex;
  width: ${leftIconWidth};

  > * {
    font-size: 30px;
  }
`

export const ActionSection = styled('div')<{ iconSize?: any }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 56px;

  svg {
    font-size: ${props => props.iconSize || '30px'};
    cursor: pointer;
  }
`

export const ExpandMoreToggle = styled(ExpandMoreIcon)<{ toggled?: boolean, scale?:number }>` //TODO check for repeated logic ExpandMoreToggle and ExpandIcon
  scale: ${props => props.scale ? props.scale : '1'};
  font-size: inherit;
  rotate: ${props => props.toggled ? '180deg' : '0deg'};
`
