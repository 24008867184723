import {FC, useState} from "react";
import {SearchBar, SearchBarData} from "./SearchBar";
import {AdvancedSearch} from "./AdvancedSearch";
import {styled} from "@mui/system";

export type AccountSearchFormProps = {
    bar: {
        placeholder: string;
        id: string;
        data: string;
    };
    form: {
        label: string;
        id: string;
        data: string;
        type: string;
    }[];
    onSearch: (params: any) => void
};

const AccountSearchFormRoot = styled('div')`
    position: relative;
`

export const AccountSearchForm: FC<AccountSearchFormProps> = ({bar, form, onSearch}) => {
    const [searchData, setSearchData] =  useState(bar.data)
    const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);

    const search = (params: any) => {
        setAdvancedSearchOpen(false);
        onSearch(params)
    }

    //TODO
    // const advancedSearchBackground = advancedSearchOpen ? 'gray' : (!isEmpty(fields) ? 'rgba(200, 200, 200, 1)' : 'transparent')
    const advancedSearchBackground = advancedSearchOpen ? 'gray' : (false ? 'rgba(200, 200, 200, 1)' : 'transparent')

    const openAdvancedSearch = () => {
        if(!advancedSearchOpen){
            setSearchData('')
        }
        setAdvancedSearchOpen(as => !as);
    }

    return <AccountSearchFormRoot>
        {bar && <SearchBar onSubmit={() => search({fuzzy: searchData})} onSearchBarChange={setSearchData} {...bar} data={searchData} advancedSearchBackground={advancedSearchBackground} inputDisabled={advancedSearchOpen} onAdvancedClick={openAdvancedSearch}/>}
        {advancedSearchOpen &&  <AdvancedSearch fields={form} onSearch={search}/>}
    </AccountSearchFormRoot>
}