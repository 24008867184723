import {FC} from "react";

import {ServiceDetailRecordContainer, ServiceDetailRoot, ServiceDetailTitle} from "./blocks/shared.styles";

import {ComponentData} from "../engine/component-renderer/componentRenderer";

export type Record = {
    description: string,
    value: string
};

export interface ServiceDetailData extends ComponentData {
    header: string,
    records: Record[]
}

export const ServiceDetail: FC<ServiceDetailData> = ({header, records}) => {
    return <ServiceDetailRoot>
        <ServiceDetailTitle>{header}</ServiceDetailTitle>
        <>{
            records.map(r => <ServiceDetailRecordContainer>
                <div>{r.description}</div>
                <div>{r.value}</div>
            </ServiceDetailRecordContainer>)
        }</>
    </ServiceDetailRoot>
}
