import {PaymentData} from "./payment-data";

import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";

export enum StepperFormFieldType {
    TextField = 'textbox',
    Date = 'date',
    Checkbox = 'checkbox',
    Array = 'array',
    Radio = 'radio',
    PhoneNumber = 'phonenumber',
    PaymentMethod = 'PaymentMethod',
    Confirmation = 'Confirmation',
}

export type ExtraContent = PaymentData | any;

export interface StepperProps extends ComponentProps<StepperProps> {
    id:          string;
    encounter:   string;
    name:        string;
    orientation: string;
    complete:    boolean;
    summary:     Summary;
    steps:       Step[];
    links:       Link[];
    stepExtraContent?: ExtraContent;
}

export interface Link {
    rel:  string;
    href: string;
}

export interface Step {
    id:          number;
    label:       string;
    active:      boolean;
    error:       boolean;
    disabled:    boolean;
    optional:    boolean;
    complete:    boolean;
    backLabel:   null | string;
    backRel:     null | string;
    backRoute:   null | string;
    skipLabel:   null;
    skipRel:     null;
    skipRoute:   null;
    nextLabel:   null | string;
    nextRel:     null;
    nextRoute:   null;
    content:     StepContentData;
    postbackURL: null | string;
}

export interface StepContentData {
    stepper: StepperProps;
    form:    Form;
    loading?: boolean;
}

export interface Form {
    description: string;
    errors:      null;
    fields:      Field[];
    data:        any;
}


export interface Field {
    id:         string;
    type:       StepperFormFieldType;
    rel:        string;
    title:      string;
    alert:      any;
    helperText: any;
    items:      any;
    enum:       any[];
    initialData: any;
}

export interface SimpleField extends Field{
    value: any;
    setValue: (x: any) => void
}

export interface Summary {
    sections: Section[];
}

export interface Section {
    header: string;
    rows:   Row[];
}

export interface Row {
    type:  string;
    alert: string;
    value: Value;
}

export interface Value {
    icon:     string;
    label:    string;
    subLabel: string;
}
