import {
    ActionChip,
    CardRow,
    CardRowLabel,
    CardSection,
    ClientChipRoot,
    PanelCard,
    StyledChip
} from "../pages/Home/AgentPanel/styles"
import {Avatar, Button, Dialog, styled} from "@mui/material";
import {FC, useState} from "react";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import isEmpty from "lodash/isEmpty";
import CancelIcon from '@mui/icons-material/Cancel';
import {NavActionData, useNavAction} from "../engine/navigation/useNavAction";
import {Link} from "./OneTimePayment";
import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {AppDispatch} from "../redux/store";
import {addGlobalLinks} from "../redux/slices/nav-slice";
import {useDispatch} from "react-redux";
import {closeConversation} from "../redux/slices/conversation-slice";

const ContactSubLabel = styled('span')`
  margin: 0 4px;
  font-size: 12px;
  color: rgb(150, 150, 150)
`

const AccountRowRoot = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const AccountRowData = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;

  > div {
    flex: 1
  }
`

const AccountRowExtraData = styled('div')`
  ${props => props.theme.breakpoints.down('lg')}{
    display: none;
  }
`

const AccountRowActions = styled('div')`
  display: flex;
  justify-content: flex-end;
`

const AccountRowAction = styled('div')`
  margin: 0 4px;
  cursor: pointer;
`

const AccountCloseIcon = styled(CancelIcon)`
  cursor: pointer;
  margin-right: 24px;
`

const AddContactIcon = styled(MuiGenericIcon)`
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
`

const DialogContent = styled('div')`
    padding: 24px;
`

const DialogActions = styled('div')`
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
`

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`

export interface AddContact {
    icon: string;
    action: NavActionData;
}

export interface ClientData extends ComponentData {
    src: string;
    label: string;
    color: string;
    onClick: NavActionData;
    onDelete: null;
}

export interface ContactData extends ComponentData {
    icon: string;
    color: string;
    label: string;
    subLabel: string;
    onClick: NavActionData;
    onDelete: NavActionData;
}

export interface ConversationData extends ComponentProps<ConversationData> {
    id: number;
    title: string;
    description: string;
    value: string;
    contactsLabel: string;
    addContact: AddContact;
    contacts: ContactData[];
    clientLabel: string;
    addClient: null;
    client: ClientData;
    accountsLabel: string;
    accounts: AccountData[];
    actionsLabel: string;
    actions: NavActionData[];
    links: Link[];
}

export interface AccountData extends ComponentData {
    label: string;
    description: string;
    chips: any[];
    icons: Icon[];
    onDelete: NavActionData;
}

export interface Icon {
    icon: string;
    onClick: NavActionData;
}



export const Conversation: FC<ConversationData> = ({
                                                       contactsLabel,
                                                       contacts,
                                                       client,
                                                       clientLabel,
                                                       accountsLabel,
                                                       actionsLabel,
                                                       actions,
                                                       accounts,
                                                       addContact,
                                                       links
                                                   }) => {
    const doAction = useNavAction()
    const dispatch: AppDispatch = useDispatch() //TODO remove store
    dispatch(addGlobalLinks(links))
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = () => {
        setDialogOpen(true)
    }

    const close = () => {
        dispatch(closeConversation())
    }

    return <PanelCard variant="outlined">
        <CardRow>
            <CardSection>
                <CardRowLabel>{contactsLabel}</CardRowLabel>
                {contacts.map(c =>
                    <StyledChip
                        onClick={() => doAction(c.onClick)}
                        onDelete={() => doAction(c.onDelete)}
                        icon={<MuiGenericIcon iconName={c.icon} sx={{
                            color: `${c.color} !important`,
                            scale: '1.4',
                            translate: '-4px',
                        }}/>}
                        label={<><span>{c.label}</span><ContactSubLabel>{c.subLabel}</ContactSubLabel></>}
                        variant="outlined"
                    />
                )}
                <AddContactIcon iconName="AddCircleOutlineOutlined" onClick={() => doAction(addContact.action)}/>
            </CardSection>
            <CardSection>
                <CardRowLabel>{clientLabel}</CardRowLabel>
                <ClientChipRoot
                    onClick={() => doAction(client.onClick)}
                    baseColor={client.color}
                    avatar={<Avatar src={client.src}/>}
                    label={client.label}
                    variant="outlined"/>
            </CardSection>
            <StyledHighlightOffIcon onClick={openDialog}/>
        </CardRow>
        <CardRow>
            <CardRowLabel>{accountsLabel}</CardRowLabel>
        </CardRow>
        <CardRow>{
            isEmpty(accounts) ? <span style={{marginLeft: 35}}>No accounts selected</span> :
                accounts.map(a => <AccountRowRoot>
                    <AccountCloseIcon onClick={() => doAction(a.onDelete)} />
                    <AccountRowData>
                        <div>{a.label}</div>
                        <AccountRowExtraData>{a.description}</AccountRowExtraData>
                    </AccountRowData>
                    <AccountRowActions>
                        {a.icons.map(i => <AccountRowAction onClick={() => doAction(i.onClick)}>
                            <MuiGenericIcon iconName={i.icon}/>
                        </AccountRowAction>)}
                    </AccountRowActions>
                </AccountRowRoot>)
        }</CardRow>
        <CardRow>
            <CardRowLabel>{actionsLabel}</CardRowLabel>
        </CardRow>
        <CardRow>
            {actions.map(a => <ActionChip icon={<MuiGenericIcon iconName={a.icon}/>} label={a.label} onClick={() => doAction(a)}/>)}
        </CardRow>
        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
            <DialogContent>
                <div>
                    Are you sure you wanna close the current conversation?
                </div>
                <DialogActions>
                    <Button onClick={close}>Yes, close</Button>
                    <Button onClick={() => setDialogOpen(false)}>No</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </PanelCard>
}