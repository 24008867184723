import {GridColDef, GridFilterModel, GridSortDirection} from '@mui/x-data-grid-premium';
import {useState} from "react";
import {produce} from "immer";
import {GridColumnResizeParams, GridColumnVisibilityModel, GridSortModel} from "@mui/x-data-grid-premium";
import {reorder} from "../../utils/reorder-array";
import {cloneDeep, groupBy, intersection, intersectionBy, isEmpty, mapValues} from "lodash";
import values from "lodash/values";
import {ConstraintDefinition} from "./GridManagementPanel";
import pick from "lodash/pick";

export interface GridViewGroup {
    id: string,
    canEdit?: boolean,
    isDefaultForSave?: boolean,
    title: string,
    views: GridView[]
}

export interface PublicGridView {
    id: string;
    title: string;
    constraint: any;
    customSearch?: any;
    filterModel?: GridFilterModel;
    columns?: {
        field: string,
        sort?: GridSortDirection,
        previousWidth?: number,
        width?: number,
        hidden?: boolean,
        storedFlex?: number,
        flex?: number,
    }[]
}

export interface GridView extends PublicGridView {
    groupName?: string,
    prototype?: GridView | ConstraintDefinition;
}

const getColumnsForView = (view: GridView) => (baseColumns: GridColDef[]) => {
    return view.columns?.map(viewCol => {
        const baseCol = baseColumns.find(bc => bc.field === viewCol.field)
        return {
            ...baseCol,
            ...viewCol
        }
    }) as GridColDef[] || baseColumns
}

const draftFromConstraint = (constraintDef: ConstraintDefinition): GridView => ({
    id: null,
    title: constraintDef.title,
    prototype: constraintDef,
    constraint: constraintDef
} as unknown as GridView) //TODO fix types and remove cast

export const useGridViewManager = (baseViewGroups: GridViewGroup[], baseColumns: GridColDef[], initialConstraintDef: ConstraintDefinition) => {
    const [views, setViews] = useState<GridView[]>([...flattenGroupViews(baseViewGroups)])
    const [draftView, setDraftView] = useState<GridView | undefined>(draftFromConstraint(initialConstraintDef))
    const [currentViewId, setCurrentViewId] = useState<string>("")

    const getCurrentView = (): GridView => {
        return draftView || views.find(v => v.id === currentViewId)!
    }

    const createDraft = () => {
        const prototypeView = views.find(v => v.id === currentViewId)
        const newDraftView = Object.assign({}, prototypeView, {id: null, prototype: prototypeView});
        setDraftView(newDraftView)
        return newDraftView
    }

    const createDraftFromConstraint = (constraintDef: ConstraintDefinition) => {
        const newDraftView = draftFromConstraint(constraintDef)
        setDraftView(newDraftView)
    }

    const selectViewById = (viewId: string) => {

        setCurrentViewId(viewId)
        setDraftView(undefined)
    }

    const changeCurrentViewSimpleAttribute = (attrName: string) => (newValue: any) => {
        if (!draftView) {
            createDraft()
        }
        setDraftView(produce(view => {
            // @ts-ignore
            view![attrName as keyof GridView] = newValue
        }))
    }

    const changeTitle = changeCurrentViewSimpleAttribute('title')

    const changeCustomSearch = changeCurrentViewSimpleAttribute('customSearch')

    const changeFilterModel = changeCurrentViewSimpleAttribute('filterModel')

    const changeSortModel = (sortModel: GridSortModel) => {
        if (!draftView) {
            createDraft()
        }
        setDraftView(produce(view => {
            if (!view?.columns) {//TODO dry
                view!.columns = cloneDeep(baseColumns) as GridColDef[]
            }
            if(isEmpty(sortModel)){
                view!.columns.forEach(c => {c.sort = undefined})
                return;
            }
            const sortValue = sortModel[0];
            const column = view!.columns.find(c => c.field === sortValue.field)
            column!.sort = sortValue.sort
        }))
    }

    const changeVisibilityModel = (visibilityModel: GridColumnVisibilityModel) => {
        if (!draftView) {
            createDraft()
        }
        setDraftView(produce(view => {
            if (!view?.columns) {//TODO dry
                view!.columns = cloneDeep(baseColumns) as GridColDef[]
            }
            view!.columns.forEach(c => c.hidden = !visibilityModel[c.field])
        }))
    }

    const changeColumnSize = (params: GridColumnResizeParams) => {
        const {field, width: newWidth} = params.colDef;
        if (!draftView) {
            createDraft()
        }
        setDraftView(produce(view => {
            if (!view!.columns) {
                view!.columns = cloneDeep(baseColumns).map(c => ({...c, storedFlex: c.flex})) as GridColDef[]
            }
            const column = view!.columns.find(c => c.field === field)
            column!.width = newWidth
            column!.flex = undefined;
            column!.storedFlex = newWidth! / (column?.previousWidth || 1)
        }))
    }

    const changeColumnOrder = (params: any) => {
        const {oldIndex, targetIndex} = params;
        if (!draftView) {
            createDraft()
        }
        setDraftView(produce(view => {
            view!.columns = reorder(getColumnsForView(view!)(baseColumns), oldIndex, targetIndex)
        }))
    }

    const saveCurrentDraft = () => {
        if (!draftView) {
            return //Nothing to do if you save a draft with no changes
        }
        const draftToSave = produce(draftView, draftView => {
            draftView.columns?.forEach(c => {
                c.width = undefined;
                c.flex = c.storedFlex;
            })
        })
        setViews(produce(views => {
            const current = views.find(v => v.id === currentViewId)!
            Object.assign(current, draftToSave, {id: current.id, prototype: undefined})
        }))
        setDraftView(undefined)
    }

    const duplicateCurrentView = () => {
        const draftToSave = draftView || createDraft()
        const newView = {
            groupName: baseViewGroups.find(vg => vg.isDefaultForSave)!.title, //TODO move to IDs
            ...draftToSave!,
            title: `Copy of ${draftToSave!.title}`,
            prototype: undefined,
            id: window.crypto.randomUUID()
        }
        setViews(produce(views => {
            views.push(newView)
        }))
        selectViewById(newView.id)
    }

    const getCurrentColumns = () => getColumnsForView(getCurrentView())(baseColumns)

    const getCurrentSortModel = () : GridSortModel => {
        return (
            getCurrentView().columns?.flatMap(
                c => c.sort?  [pick(c, 'field', 'sort')] : []
            ) || []
        ) as GridSortModel
    }

    const getCurrentVisibilityModel = () : GridColumnVisibilityModel => {
        const viewColumns = getCurrentView().columns || []
        return baseColumns.reduce(
            (acc, c) => ({...acc, [c.field]: !viewColumns.find(vc => vc.field === c.field)?.hidden}),
            {}
        ) as GridColumnVisibilityModel
    }

    return {
        views,
        viewGroups: unFlattenGroupViews(views) as GridViewGroup[],
        selectViewById,
        saveCurrentDraft,
        duplicateCurrentView,
        getters:{
            getCurrentSortModel,
            getCurrentVisibilityModel,
            getCurrentView,
            getCurrentColumns,
        },
        mutators: {
            changeTitle,
            changeFilterModel,
            createDraftFromConstraint,
            changeCustomSearch,
            changeSortModel,
            changeVisibilityModel,
            changeColumnSize,
            changeColumnOrder
        }
    }
}

const flattenGroupViews = (viewGroups: GridViewGroup[]) => {//TODO remvoe idnex from id when API is fxed
    return viewGroups.flatMap((vg, i) => vg.views.map(v => ({...v, id: `${v.id}${i}`, groupName: vg.title})))
}

const unFlattenGroupViews = (flatViews: GridView[]) => {
    const viewListsByGroupName = groupBy(flatViews.filter(v => v.groupName), 'groupName')
    const viewGroupsByName = mapValues(viewListsByGroupName, (groupViews, groupName) => ({
        title: groupName,
        views: groupViews
    }));
    return values(viewGroupsByName)
}
