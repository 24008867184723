import {AgentPanelContainer, ConversationId, PanelCard} from "./styles"
import {Typography, Button, styled} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux/store";
import {getConversationUrl, reloadConversation, requestConversation} from "../../../redux/slices/conversation-slice";
import isEmpty from "lodash/isEmpty";
import {ConversationData} from "../../../components/Conversation";
import {RightHandDrawer} from "../../../engine/structural-sections/section-components/RightHandDrawer";
import { renderComponent } from "../../../engine/component-renderer/componentRenderer";
import {useSection} from "../../../engine/useSection";
import {StructuralSectionsMap} from "../../../engine/structural-sections/StructuralSectionsMap";
import {CenteredCircularProgress} from "../../../components/blocks/CenteredCircularProgress/CenteredCircularProgress";
import { useState } from "react";
import { TextField } from "@mui/material";
import {AxiosError} from "axios";

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Actions = styled('div')`
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
`
export const CTAButton = styled(Button)`
  font-weight: bold;
`

//TODO maybe some of this code needs to be moved up the tree when global nav is implemented
export const AgentPanel = () => {
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [errored, setError] = useState(false);
    const currentConversationUrl = getConversationUrl()
    const conversation = useSelector((state: RootState) => state.conversation)
    const dispatch: AppDispatch = useDispatch()
    const { isOpen: isDrawerOpen } = useSection(StructuralSectionsMap.RIGHT_DRAWER)
    const { isOpen: isDrawerDialogOpen } = useSection(StructuralSectionsMap.DRAWER_DIALOG)

    const isDrawerVisible = isDrawerOpen || isDrawerDialogOpen

    const setFieldData = (value: string) => {
        setInputValue(value);
    };

    const lookup = async () => {
        if (inputValue) {
            setError(false)
            setLoading(true);
            try {
                await dispatch(requestConversation(inputValue));
            } catch (e) {
                setError(true)
            } finally {
                setLoading(false);
            }
        }
    }

    const reload = async () => {
        setError(false)
        setLoading(true);
        try {
            await dispatch(reloadConversation());
        } catch (e) {
            setError(true)
        } finally {            
            setLoading(false);
        }
    }

    return <AgentPanelContainer drawerOpen={isDrawerVisible}>
        {!isEmpty(conversation) && (
            <ConversationId>
                <Typography variant="subtitle1" gutterBottom>Conversation</Typography>
                <Typography variant="subtitle1" gutterBottom>{`ID: ${conversation?.id || '--'}`}</Typography>
            </ConversationId>
        )}
        {!isEmpty(conversation) ? renderComponent()(conversation as ConversationData) : (
            <PanelCard variant="outlined">
                {loading ? <Actions>
                    <CenteredCircularProgress />
                </Actions> :
                    <Container>
                        {errored ?? <Typography variant="subtitle1" gutterBottom>Unable to locate a Conversation by that ID</Typography>}
                        <TextField variant="standard" label="Conversation ID" onChange={e => setFieldData(e.target.value)} fullWidth />
                        <Actions>
                            <CTAButton onClick={lookup}>Lookup Conversation</CTAButton>

                            {!isEmpty(currentConversationUrl) && <Button onClick={reload}>Reload Last Conversation</Button>}
                        </Actions>
                        </Container>
                }
            </PanelCard>
        )}
    </AgentPanelContainer>
}
