import {FC, useState} from "react";
import {SectionTitle} from "./blocks/shared.styles";
import {ChipTab, ChipTabContainer} from "./Hulabill";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import {styled} from "@mui/material";
import {ComponentData, ComponentProps} from "../engine/component-renderer/componentRenderer";

const ChipTabSectionContent = styled('div')`
    margin-top: 16px
`

export interface ChipTabSectionProps extends ComponentProps<ChipTabSectionProps> {
    title: string;
    chips: {
        icon: string;
        label: string;
        details: ComponentData
    }[];
}

export const ChipTabSection: FC<ChipTabSectionProps> = ({title, chips, renderChild}) => {
    const [selected, setSelected] = useState<number>(0)

    return <>
        <div>
            <SectionTitle>{title}</SectionTitle>
            <ChipTabContainer justify={'flex-start'}>
                {chips && chips.map((c,i) => <ChipTab selected={i === selected} label={c.label} icon={c.icon? <MuiGenericIcon iconName={c.icon}/> : undefined} onClick={() => setSelected(i)}/>)}
            </ChipTabContainer>
        </div>
        <ChipTabSectionContent>
            {chips && chips[selected]?.details && renderChild(chips[selected]?.details)}
        </ChipTabSectionContent>
    </>

}