import {FC, useState} from "react";
import {Button, Card, styled, TextField} from "@mui/material";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined';
import {DatePicker} from "@mui/x-date-pickers";
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {DateTime} from "luxon";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import isEmpty from "lodash/isEmpty";
import {
    PaymentOptionHeader,
    PaymentOptionContainer,
    PaymentOptionTextFieldContainer,
    PaymentOptionTextFieldHeader, PaymentOptionCTA, PaymentOptionData
} from "./OneTimePayment";
import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";
import {startPayment} from "../redux/slices/hulabill-slice";
import {ModalType, useNavAction} from "../engine/navigation/useNavAction";

const PaymentPlanOption = styled('div')<{ selected?: boolean }>((props) => `
    height: 50px;
    background: ${props.selected ? props.theme.palette.primary.main : 'transparent'};
    border-radius: 8px;
    border: 1px solid ${props.theme.palette.primary.main};
    margin-top: 8px;
    color: ${props.selected ? 'white' : props.theme.palette.primary.main};;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    cursor: pointer;
`)

const PaymentPlanOptionButton: FC<{ html: string, selected?: boolean, onClick: () => void }> = ({
                                                                                                    html,
                                                                                                    selected,
                                                                                                    onClick
                                                                                                }) => {


    return <PaymentPlanOption selected={selected} dangerouslySetInnerHTML={{__html: html}} onClick={onClick}>
    </PaymentPlanOption>
}

export const PaymentPlan: FC<PaymentOptionData> = ({
                                                       balanceHeader,
                                                       balanceDue,
                                                       paymentDateHeader,
                                                       defaultPaymentDate,
                                                       dateErrorMessage,
                                                       allowedPaymentDateRange,
                                                       dateIcon,
                                                       currencyIcon,
                                                       paymentOptions,
                                                       postbackLabel,
                                                       postbackUrl
                                                   }) => {
    const [dateError, setDateError] = useState('')
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
    const [date, setDate] = useState(DateTime.fromISO(defaultPaymentDate)) //TODO: Maybe some of this logic can be DRY-ed into a hook
    const doAction = useNavAction()
    const dispatch: AppDispatch = useDispatch()


    const proceedToPayment = () => {
        // dispatch(startPayment(postbackUrl,{
        //     ...paymentOptions![selectedOptionIndex].data,
        //     paymentDate: date,
        // }))

        const data = {
            id: paymentOptions![selectedOptionIndex].id,
            amount: paymentOptions![selectedOptionIndex].terms.defaultAmount,
            paymentDate: date
        }
        
        doAction({
            "@type": "@Action",
            "data": data,
            "rel": "postbackUrl",//TODO fix,
            "modalType": ModalType.DRAWER_DIALOG
        })
        
        //dispatch(startPayment(postbackUrl,data))
    }

    return <>
        <PaymentOptionContainer variant={'outlined'}>
            <PaymentOptionHeader>
                <span>{balanceHeader}</span>
                <span>{balanceDue}</span>
            </PaymentOptionHeader>
            <div>
                {paymentOptions?.map((po, i) =>
                    <PaymentPlanOptionButton html={po.termsMarkup} selected={i === selectedOptionIndex}
                                             onClick={() => setSelectedOptionIndex(i)}/>)}
            </div>
            <PaymentOptionTextFieldHeader>{paymentDateHeader}</PaymentOptionTextFieldHeader>
            <PaymentOptionTextFieldContainer>
                <MuiGenericIcon iconName={dateIcon}/>
                <MobileDatePicker
                    maxDate={DateTime.now().plus({days: allowedPaymentDateRange})}
                    minDate={DateTime.now().minus({days: allowedPaymentDateRange})}
                    onError={(error) => setDateError(error ? dateErrorMessage : '')}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            helperText: dateError,
                        },
                    }}
                    value={date}
                    onChange={d => d && setDate(d)}
                />
            </PaymentOptionTextFieldContainer>
        </PaymentOptionContainer>
        <PaymentOptionCTA variant="contained" onClick={proceedToPayment}>{postbackLabel}</PaymentOptionCTA>
    </>;
}