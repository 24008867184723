import React, {FC} from "react";
import {Avatar, MenuItem as MUIMenuItem, styled} from "@mui/material";
import {DropDown} from "../AppNav";

import {ComponentData} from "../engine/component-renderer/componentRenderer";

const ScaledAvatar = styled(Avatar)`
  scale: 0.85
`

export interface AvatarMenuData extends ComponentData {
    src: string;
    initials: string;
    color: string;
    menu: ComponentData[];
}

export const AvatarMenu: FC<AvatarMenuData> = ({initials, menu}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <ScaledAvatar onClick={handleClick}>{initials}</ScaledAvatar>
            <DropDown
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {menu && <>
                    <MUIMenuItem onClick={handleClose}>Profile</MUIMenuItem>
                    <MUIMenuItem onClick={() => window.location.href = "/logout"}>Logout</MUIMenuItem>
                </>}
            </DropDown>
        </div>
    );
}