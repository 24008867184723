import {FC} from "react";
import {ClickableTuneIcon, SearchBarInput} from "../../pages/Home/AgentPanel/styles";
import SearchIcon from "@mui/icons-material/Search";

export type SearchBarData = {
    data?: string;
    placeholder: string;
    id: string;
};

export type SearchBarProps = SearchBarData & {
    advancedSearchBackground: string,
    inputDisabled: boolean,
    onAdvancedClick: () => void,
    onSearchBarChange: (value: any) => void,
    onSubmit: () => void,
}

export const SearchBar: FC<SearchBarProps> = ({
                                                  placeholder,
                                                  id,
                                                  advancedSearchBackground,
                                                  inputDisabled,
                                                  onAdvancedClick,
                                                  data,
                                                  onSearchBarChange,
                                                  onSubmit
}) => {

    const onKeyDown = (e: any) =>{
        if((e.keyCode == 13 || e.key === 'Enter') && e.target.value !== ""){
            onSubmit()
        }
    }

    return (<SearchBarInput variant="outlined" InputProps={{
        id,
        placeholder,
        value: data,
        onKeyDown: onKeyDown,
        onChange: e => onSearchBarChange(e.target.value),
        endAdornment: <ClickableTuneIcon background={advancedSearchBackground} onClick={onAdvancedClick}/>,
        startAdornment: <SearchIcon/>
    }}
    disabled={inputDisabled}
    />)
}
