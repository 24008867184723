import React, {FC, useEffect, useState} from "react";
import {CircularProgress, styled, TextField} from "@mui/material";
import {Field, Form, StepContentData, StepperFormFieldType} from "../../redux/stepper-data";
import {PaymentMethodField} from "./PaymentMethodField/PaymentMethodField";
import {StepNavigation, StepNavProps} from "./StepNavigation";
import {ExtraContentProps, Stepper} from "./Stepper";
import {
    StepperCheckBox,
    StepperConfirmation, StepperDropDown,
    StepperRadioButton,
    StepperTextField
} from "./StepperFormFields";
import {loadStepperExtraContent, navigate, sendTextData, StepperAction} from "../../redux/slices/stepper-slice";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";

const StepDescription = styled('div')`
  margin: 16px 0;
`

const FieldWrapper = styled('div')`
  :not(:first-child) {
    margin-top: 16px;
  }
`
//Simple input fields that can be combined in a single step. Cannot have extra content and don't handle navigation
const STEP_CONTENT_SIMPLE_FIELDS: { [x: string]: any } = {
    [StepperFormFieldType.TextField]: () => StepperTextField,
    [StepperFormFieldType.Checkbox]: () => StepperCheckBox,
    [StepperFormFieldType.Date]: () => StepperTextField,
    [StepperFormFieldType.Array]: () => StepperDropDown,
    [StepperFormFieldType.Radio]: () => StepperRadioButton,
    [StepperFormFieldType.PhoneNumber]: () => StepperTextField,
}

//Complex fields that have custom complex behavior, non-combinable, for ad-hoc cases. Can have extra content and handle navigation
const STEP_CONTENT_COMPLEX_FIELDS: { [x: string]: any } = {
    [StepperFormFieldType.PaymentMethod]: () => PaymentMethodField,
    [StepperFormFieldType.Confirmation]: () => StepperConfirmation,
}

const isComplex = (fieldType: StepperFormFieldType) => !!STEP_CONTENT_COMPLEX_FIELDS[fieldType]

export const StepContent: FC<StepContentData & StepNavProps & ExtraContentProps> = (props) => {
    const {
        form,
        stepper,
        loading
    } = props;
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!isValidForm()) {
            console.error(`Error loading Step. Step config should either have only simple fields or only one complex field. Also nested steppers are not supported`)
        }
    }, [form])

    const isSimpleValidForm = () => form?.fields.every(f => !isComplex(f.type))

    const isComplexValidForm = () => form?.fields.length === 1 && isComplex(form?.fields[0].type)

    const isValidForm = () => isSimpleValidForm() || isComplexValidForm() && !stepper

    if (loading) {
        return <CircularProgress/>
    }

    if (!isValidForm()) {
        return <span>Error loading step</span>
    }

    return <>
        {form?.description && <StepDescription>{form.description}</StepDescription>}
        {isSimpleValidForm() ? <SimpleStepForm {...props}/> : <ComplexStepForm {...props}/>}
    </>
}


export const ComplexStepForm: FC<StepContentData & StepNavProps & ExtraContentProps> = ({
                                                                                            form,
                                                                                            extraContent,
                                                                                            nextLabel,
                                                                                            backLabel,
                                                                                            onClose
                                                                                        }) => {
    const dispatch: AppDispatch = useDispatch()
    const singleField = form?.fields[0]
    const FieldComponent = STEP_CONTENT_COMPLEX_FIELDS[singleField.type]()

    useEffect(() => {
        if (!extraContent && singleField.rel) { //TODO Check why this loops infinitely without the '!extraContent'
            dispatch(loadStepperExtraContent(singleField.rel))
        }
    }, [form])

    return <FieldComponent {...singleField} onClose={onClose} extraContent={extraContent} form={form}
                           nextLabel={nextLabel}
                           backLabel={backLabel} initialData={form?.data && form?.data[singleField.id]}/>
}

export const SimpleStepForm: FC<StepContentData & StepNavProps & ExtraContentProps> = ({
                                                                                           form,
                                                                                           nextLabel,
                                                                                           backLabel,
                                                                                           onClose
                                                                                       }) => {
    const dispatch: AppDispatch = useDispatch()
    const [values, setValues] = useState(form.data);

    const navigateForward = () => dispatch(navigate(StepperAction.NEXT, values, onClose))

    const navigateBack = () => dispatch(navigate(StepperAction.BACK, null, onClose))

    const setValue = (fieldId: string) => (newValue: any) => setValues((values: any) => ({...values, [fieldId]: newValue}))

    return <>
        {form?.fields?.map((f: Field) => {
            const FieldComponent = STEP_CONTENT_SIMPLE_FIELDS[f.type]()
            return <FieldWrapper><FieldComponent {...f} value={values[f.id]} setValue={setValue(f.id)}/></FieldWrapper>
        })}
        <StepNavigation nextLabel={nextLabel} backLabel={backLabel} navigateForward={navigateForward}
                        navigateBack={navigateBack}/>
    </>
}