import React from 'react';
import './App.css';
import {store} from "./redux/store";
import {Provider} from 'react-redux'
import {Home} from "./pages/Home/Home";
import {RouteObject} from "react-router";
import {Login} from "./pages/Login/Login";
import {LoginSuccess} from "./pages/Login/LoginSuccess";
import {Logout} from "./pages/Login/Logout";
import {createTheme, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon'
import {AppNav} from "./AppNav";
import {pdfjs} from 'react-pdf';
import {PDFViewer} from "./PDFViewer";
import {GlobalDialog} from "./engine/structural-sections/section-components/Dialog";
import {RightHandDrawer} from "./engine/structural-sections/section-components/RightHandDrawer";
import {LicenseInfo} from '@mui/x-license';

LicenseInfo.setLicenseKey('d975c323828f4eb0fd31979e1b8e4f51Tz04NjQyMSxFPTE3NDE5ODE5ODAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y');

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

export const baseRoutes: { [routeName: string]: RouteObject } = {
    Home: {
        path: "/",
        element: <Home/>,
    },
    // DataGrid: {
    //     path: "/datagrid",
    //     element: <SampleGrid/>,
    // },
    // Grouped: {
    //     path: "/grouped",
    //     element: <GroupedGrid/>,
    // },
    // Account: {
    //     path: "/accountgrid",
    //     element: <AccountGrid/>,
    // },
    // AccountGrid: {
    //     path: "/accounts",
    //     element: <SampleAccountEnhancedDataGrid/>,
    // },
    // Tickets: {
    //     path: "/tickets",
    //     element: <SampleTicketsEnhancedDataGrid/>,
    // },
    AgentDesk: {
        path: "/agentDesk",
        element: <Home/>,
    },
    Login: {
        path: "/login",
        element: <Login/>,

    },
    LoginSuccess: {
        path: "/loginSuccess",
        element: <LoginSuccess/>,
    },
    Logout: {
        path: "/logout",
        element: <Logout/>,
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(67,67,91)',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    fontSize: '13px',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                    '&.Mui-focused':{
                        transform: 'translate(0, 5px) scale(0.75)',
                    },
                    '&.MuiInputLabel-shrink':{
                        transform: 'translate(0, 5px) scale(0.75)',
                    }
                },
            },
        },
    },
});

const App = () => {
    return <Provider store={store}>
       <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <RightHandDrawer/>
                <GlobalDialog/>
                <PDFViewer/>
                <AppNav>
                </AppNav>
            </LocalizationProvider>
        </ThemeProvider>
    </Provider>
}

export default App;
