import {PaymentData} from "../../../redux/payment-data";
import {FC} from "react";
import {Card, styled} from "@mui/material";
import {AppDispatch} from "../../../redux/store";
import {useDispatch} from "react-redux";
import {navigate, removePaymentMethod, StepperAction} from "../../../redux/slices/stepper-slice";
import {PrimaryButton, StepNavigation, StepNavProps} from "../StepNavigation";

const SavedPaymentMethodRoot = styled(Card)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PaymentMethodError = styled('div')`
  font-size: 12px;
  color: #ff3333;
  margin-bottom: 16px;
`

const SavedPaymentMethodLabel = styled('div')`
font-weight: 600;
`

export const SavedPaymentMethod: FC<StepNavProps & PaymentData> = ({preferredPaymentMethod, savedPaymentMethods, backLabel, nextLabel, onClose}) => {
    const dispatch: AppDispatch = useDispatch()

    const preferredSavedPaymentMethod = savedPaymentMethods.find(pm => pm.id === preferredPaymentMethod)

    const navigateForward = () => dispatch(navigate(StepperAction.NEXT, preferredPaymentMethod))

    const navigateBack = () => dispatch(navigate(StepperAction.BACK, null,onClose))

    return <>
        <PaymentMethodError>{preferredSavedPaymentMethod.displayDetails.subLabel}</PaymentMethodError>
        <SavedPaymentMethodRoot>
            <SavedPaymentMethodLabel>{preferredSavedPaymentMethod.displayDetails.label}</SavedPaymentMethodLabel>
            <PrimaryButton color={"primary"} onClick={() => dispatch(removePaymentMethod())}>{preferredSavedPaymentMethod.displayDetails.removeLabel}</PrimaryButton>
        </SavedPaymentMethodRoot>
        <StepNavigation nextLabel={nextLabel} backLabel={backLabel} navigateForward={navigateForward} navigateBack={navigateBack}/>
    </>
}