import {FC, useEffect} from "react";
import {
    Card,
    Step,
    StepContent as StepContainer,
    StepLabel as MuiStepLabel,
    Stepper as MuiStepper,
    styled
} from "@mui/material";
import {MuiGenericIcon} from "../../utils/dynamic-icon";
import {ExtraContent} from "../../redux/stepper-data";
import {StepContent} from "./StepContent";
import {loadBaseStepper, StepperState} from "../../redux/slices/stepper-slice";
import {getStepperSlice} from "../../redux/base-selectors";
import {useDispatch, useSelector} from "react-redux";

const SummaryHeader = styled('h2')`
  font-size: 14px;
  font-weight: 600;
`

const SummaryDetailCard = styled(Card)`
  border-radius: 16px;
  margin: 16px 0;
  padding: 16px;
`

const PaymentDetailCardRow = styled('div')`
  display: flex;
  align-items: end;
  font-size: 14px;

  :not(:last-child) {
    margin-bottom: 8px;
  }

  > svg {
    margin-right: 8px;
  }
`

export const StepLabel = styled(MuiStepLabel)`
  text-transform: uppercase;
`

export type ExtraContentProps = { extraContent?: ExtraContent };


export const Stepper: FC<StepperState> = ({summary, steps, stepExtraContent, loading, closeSection}) => {
    const activeStepIndex = steps?.findIndex(s => s.active)

    return <div>
        {summary?.sections?.map(s => <>
            <SummaryHeader>{s.header}</SummaryHeader>
            <SummaryDetailCard variant={"outlined"}>
                {s.rows.map(({value}) => <PaymentDetailCardRow>
                    <MuiGenericIcon iconName={value.icon}/>{value.label}
                </PaymentDetailCardRow>)}
            </SummaryDetailCard>
        </>)}
        <MuiStepper key={steps?.length || 0 /*This is a small hack to re-render the stepper because changing the steps messes up with it a bit*/} activeStep={activeStepIndex} orientation="vertical">
            {steps?.map((s, i) => {
                    return <Step key={s.id} completed={i <= (activeStepIndex || 0)}>
                        <StepLabel>
                            {s.label}
                        </StepLabel>
                        <StepContainer>
                            <StepContent onClose={closeSection} loading={loading} {...s.content} extraContent={stepExtraContent} nextLabel={s.nextLabel} backLabel={s.backLabel}/>
                        </StepContainer>
                    </Step>;
                }
            )}
        </MuiStepper>
    </div>;
}

export const StepperContainer: FC<{ data: any, postbackUrl:string, closeSection: ()=>void}> = ({data, postbackUrl, closeSection}) => {
    const stepperData = useSelector(getStepperSlice)
    const dispatch = useDispatch()

    useEffect(() => {
        // @ts-ignore
        dispatch(loadBaseStepper(postbackUrl, data))
    }, [])

    return <Stepper closeSection={closeSection} {...stepperData}/>
}

