import {Dialog, styled} from "@mui/material";
import {StructuralSectionsMap} from "../StructuralSectionsMap";
import {useSection} from "../../useSection";
import {CenteredCircularProgress} from "../../../components/blocks/CenteredCircularProgress/CenteredCircularProgress";

const DialogContainer = styled('div')`
    padding: 32px;
`

export const GlobalDialog = () => {
    const {
        isLoading,
        isOpen,
        close,
        renderContent
    } = useSection(StructuralSectionsMap.DIALOG)

    return <Dialog onClose={close} open={isOpen}>
        <DialogContainer>
            {isLoading? <CenteredCircularProgress/> : renderContent()}
        </DialogContainer>
    </Dialog>
}
