import {Button, styled} from "@mui/material";
import React, {FC} from "react";
import {GridView, GridViewGroup} from "./GridViewModel";
import {produce} from "immer";
import {remove} from "lodash";
import {GridFilterItem} from "@mui/x-data-grid-premium";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {ComponentData} from "../../engine/component-renderer/componentRenderer";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

const GridManagementPanelRoot = styled('div')`
  width: 300px;
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  padding: 16px;

  > h1 {
    font-size: 24px;
  }

  > h2 {
    text-transform: uppercase;
    font-size: 12px;
  }
`

const QuickFilters = styled('div')`
  font-size: 14px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 20px;
`

const SavedViews = styled('div')`
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
`

const SavedViewData = styled('div')`
  display: flex;
  align-items: center;
`

const SavedViewActions = styled('div')`
  display: flex;

  > svg {
    color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
    cursor: pointer;
    margin-left: 8px;

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }
`


const SavedViewDot = styled('div')<{ color: string }>`
  background: ${props => props.color};
  //According to Material Design directives, the 'left icon' element should be 18px wide.
  //Since our dot only needs to have an 8px diameter, 5px of extra margin are added to each side to fill the 18px;
  //Also separation between 'left icon' and actual content shoudl be 8px so right margin is 8+5 = 13px
  border-radius: 8px;
  height: 8px;
  width: 8px;
  margin-right: 13px;
  margin-left: 5px;
`

const DraftViewDot = styled('div')`
  background: #FF9800;
  height: 4px;
  width: 4px;
  border-radius: 8px;
  margin-left: 4px;
  align-self: baseline;
`


const SavedView = styled('div')<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 32px);
  margin-bottom: 16px;
  cursor: pointer;
  padding: 0 8px 0 8px;
  height: 32px;
  border-radius: 16px;
  background: ${props => props.selected ? `rgba(224, 224, 224, 1)` : `transparent`};

  svg {
    display: none;
  }

  &:hover {
    background: ${props => props.selected ? `rgba(224, 224, 224, 1)` : `rgba(244, 244, 244, 1)`};;

    svg {
      display: block;
    }
  }
`

const QuickFilter = styled('div')<{ selected?: boolean }>`
  width: calc(100% - 32px - 8px);
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  cursor: pointer;
  padding: 0 16px;
  height: 32px;
  align-items: center;
  background: ${props => props.selected ? `rgba(224, 224, 224, 1)` : `transparent`};
  border-radius: 16px;

  &:hover {
    background: ${props => props.selected ? `rgba(224, 224, 224, 1)` : `rgba(244, 244, 244, 1)`};;
  }
`

export type QuickFilterValue = {
    value: string,
    displayValue?: string,
    numberOfResults: number
};


export interface GridManagementPanelProps {
    viewGroups: GridViewGroup[],
    onSavedViewClick: (view: GridView) => void, //TODO rename
    selectedView: GridView,
    children: ReactJSXElement
}

export interface ConstraintPillsProps {
    constraintDefs: ConstraintDefinition[],
    currentConstraintDef?: ConstraintDefinition,
    onConstraintChange: (c: ConstraintDefinition) => void

}

export interface ConstraintDefinition {
    id: string;
    title: string,
    constraint: any,
    numberOfResults?: number;
}

export const ConstraintPills: FC<ConstraintPillsProps> = ({
                                                              constraintDefs,
                                                              onConstraintChange,
                                                              currentConstraintDef
                                                          }) => {

    return <QuickFilters>
        {constraintDefs.map((constraintDef) => <QuickFilter
                    onClick={() => onConstraintChange(constraintDef)}
                    selected={constraintDef.id === currentConstraintDef?.id}
                >
                    <span>{constraintDef.title}</span>
                    <span>{constraintDef.numberOfResults}</span>
                </QuickFilter>
        )}
    </QuickFilters>
}

export const GridManagementPanel: FC<GridManagementPanelProps> = ({
                                                                      viewGroups,
                                                                      onSavedViewClick,
                                                                      selectedView,
                                                                      children,
                                                                  }) => {

    const isViewSelected = (v: GridView) => {
        return v.id === selectedView.id || v.id === selectedView.prototype?.id
    }

    return <GridManagementPanelRoot>
        <h1>Tickets</h1>
        {children}
        {viewGroups.map(vg => <>
            <h2>{vg.title}</h2>
            <SavedViews>
                {vg.views.map((v: GridView) =>
                    <SavedView onClick={() => onSavedViewClick(v)} selected={isViewSelected(v)}>
                        <SavedViewData>
                            <SavedViewDot color={'#FF5999'}/>
                            {v.title}
                            {v.id === selectedView.prototype?.id ? <DraftViewDot/> : null}
                        </SavedViewData>
                        <SavedViewActions>
                            <DriveFileMoveIcon/>
                            <DeleteIcon/>
                        </SavedViewActions>
                    </SavedView>
                )}
            </SavedViews>
        </>)}
    </GridManagementPanelRoot>
}