import {GridColDef, GridFilterModel, GridPaginationModel} from "@mui/x-data-grid-premium";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {ConstraintDefinition} from "../components/EnhancedDataGrid/GridManagementPanel";
import {mapValues, omit} from "lodash";

const isDate = (key: any, baseColumns: GridColDef[]) =>{
    return baseColumns.find(col => col.field === key)?.type === "date"
}

const transformDates = (rows: any[], baseColumns: GridColDef[]) => rows.map(r => {
    return mapValues(r, (value, key) => isDate(key, baseColumns) ? new Date() : value )
});

export class DataSource {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    getDataGrid(constraint: ConstraintDefinition, customSearch: any){
        console.log("------ NEW SEARCH:----------:")
        console.log("constraint:", omit(constraint, 'path'))
        console.log("customSearch:", omit(customSearch, 'path'))
        const rawData= {
            "@type": "@DataGrid",
            "alert": "More than 200 rows were returned. Refine your search and repeat",
            "columns": [
                {
                    "field": "id",
                    "headerName": "ID",
                    "flex": 1,
                },
                {
                    "field": "patient",
                    "headerName": "Patient Name",
                    "flex": 1,
                    "minWidth": 80
                },
                {
                    "field": "responsibleParty",
                    "headerName": "Resp. Party Name",
                    "flex": 1,
                    "minWidth": 80
                },
                {
                    "field": "birthdate",
                    "type": "date",
                    "headerName": "Birthdate",
                    "flex": 1,
                    "minWidth": 80
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "flex": 1,
                    "minWidth": 80
                },
                {
                    "field": "address",
                    "headerName": "Address",
                    "flex": 1,
                    "minWidth": 80
                },
                {
                    "field": "accountGroup",
                    "type": "singleSelect",
                    "valueOptions": [
                        "Medical",
                        "Dental"
                    ],
                    "headerName": "Account Group",
                    "flex": 1,
                    "minWidth": 80
                }
            ],
            "rows": [
                {
                    "id": 1,
                    "patient": "John Doe",
                    "responsibleParty": "Jim Doe",
                    "birthdate": "new Date()",
                    "phone": "(123) 123-1234",
                    "address": "1213 Venice Blvd, Venice, CA 90291",
                    "accountGroup": "Medical"
                },
                {
                    "id": 2,
                    "patient": "John Doe",
                    "responsibleParty": "Jane Doe",
                    "birthdate": "new Date()",
                    "phone": "(123) 123-1234",
                    "address": "1213 Venice Blvd, Venice, CA 90291",
                    "accountGroup": "Dental"
                },
                {
                    "id": 3,
                    "patient": "John Doe",
                    "responsibleParty": "Jane Doe",
                    "birthdate": "new Date()",
                    "phone": "(123) 123-1234",
                    "address": "1213 Venice Blvd, Venice, CA 90291",
                    "accountGroup": "Medical"
                },
                {
                    "id": 4,
                    "patient": "John Doe",
                    "responsibleParty": "Jane Doe",
                    "birthdate": "new Date()",
                    "phone": "(123) 123-1234",
                    "address": "1213 Venice Blvd, Venice, CA 90291",
                    "accountGroup": "Dental"
                }
            ],
            "pagination": {
                "page": 0,
                "pageSize":  5,
                "rowCount": 10
            },
            "links": [
                {
                    "rel": "self",
                    "href": "https://o5m7sgjr7b.execute-api.us-east-1.amazonaws.com/dev/data/accounts"
                }
            ]
        }

        return {
            ...rawData,
            rows: transformDates(rawData.rows, rawData.columns as GridColDef[])
        }

    }

    // getRecords(paginationModel: GridPaginationModel,filterModel?: GridFilterModel, sortModel?: GridSortModel, extraParams?: any){
    //     console.log('returning data', filterModel, sortModel, paginationModel, extraParams)
    //     let rows = []
    //     if(this.baseUrl?.includes("tickets")){
    //         rows = [
    //             {
    //                 "id":1,
    //                 "ticketNumber":"4321423432",
    //                 "date":"new Date()",
    //                 "name":"John Doe",
    //                 "carrier":"Medicaid",
    //                 "order":"Primary",
    //                 "group":"John Muir",
    //                 "type":"Rebill",
    //                 "expiration":"new Date()",
    //                 "status":"Open"
    //             },
    //             {
    //                 "id":2,
    //                 "ticketNumber":"12423456534",
    //                 "date":"new Date()",
    //                 "name":"John Doe",
    //                 "carrier":"Medicaid",
    //                 "order":"Primary",
    //                 "group":"Bay Imaging",
    //                 "type":"Rebill",
    //                 "expiration":"new Date()",
    //                 "status":"Open"
    //             },
    //             {
    //                 "id":3,
    //                 "ticketNumber":"4536324",
    //                 "date":"new Date()",
    //                 "name":"John Doe",
    //                 "carrier":"Other Carrier",
    //                 "order":"Primary",
    //                 "group":"Bay Imaging",
    //                 "type":"Rebill",
    //                 "expiration":"new Date()",
    //                 "status":"Pending"
    //             },
    //             {
    //                 "id":4,
    //                 "ticketNumber":"456456347637",
    //                 "date":"new Date()",
    //                 "name":"John Doe",
    //                 "carrier":"Other Carrier",
    //                 "order":"Primary",
    //                 "group":"Bay Imaging",
    //                 "type":"Rebill",
    //                 "expiration":"new Date()",
    //                 "status":"Pending"
    //             },
    //             {
    //                 "id":5,
    //                 "ticketNumber":"565465436465",
    //                 "date":"new Date()",
    //                 "name":"John Doe",
    //                 "carrier":"Medicaid",
    //                 "order":"Primary",
    //                 "group":"Bay Imaging",
    //                 "type":"Rebill",
    //                 "expiration":"new Date()",
    //                 "status":"Answered"
    //             },
    //             {
    //                 "id":6,
    //                 "ticketNumber":"098765412",
    //                 "date":"new Date()",
    //                 "name":"John Doe",
    //                 "carrier":"Medicaid",
    //                 "order":"Primary",
    //                 "group":"Bay Imaging",
    //                 "type":"Rebill",
    //                 "expiration":"new Date()",
    //                 "status":"Pending"
    //             }
    //         ]
    //     } else {
    //         rows = [
    //             {
    //                 "id":1,
    //                 "patient":"John Doe",
    //                 "responsibleParty":"Jim Doe",
    //                 "birthdate":"new Date()",
    //                 "phone":"(123) 123-1234",
    //                 "address":"1213 Venice Blvd, Venice, CA 90291",
    //                 "accountGroup":"Hospital"
    //             },
    //             {
    //                 "id":2,
    //                 "patient":"John Doe",
    //                 "responsibleParty":"Jane Doe",
    //                 "birthdate":"new Date()",
    //                 "phone":"(123) 123-1234",
    //                 "address":"1213 Venice Blvd, Venice, CA 90291",
    //                 "accountGroup":"Clinic"
    //             },
    //             {
    //                 "id":3,
    //                 "patient":"John Doe",
    //                 "responsibleParty":"Jane Doe",
    //                 "birthdate":"new Date()",
    //                 "phone":"(123) 123-1234",
    //                 "address":"1213 Venice Blvd, Venice, CA 90291",
    //                 "accountGroup":"Hospital"
    //             },
    //             {
    //                 "id":4,
    //                 "patient":"John Doe",
    //                 "responsibleParty":"Jane Doe",
    //                 "birthdate":"new Date()",
    //                 "phone":"(123) 123-1234",
    //                 "address":"1213 Venice Blvd, Venice, CA 90291",
    //                 "accountGroup":"Clinic"
    //             }
    //         ]
    //     }
    //
    //     return {
    //         rows,
    //         pagination: {
    //             ...paginationModel,
    //             rowCount: 100
    //         }
    //     }
    // }
}