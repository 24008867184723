import {createSlice, original, Slice} from "@reduxjs/toolkit";
import {addPath} from "../../utils/addPath";
import {cloneDeep, get} from "lodash";
import {AppDispatch, GetState, RootState} from "../../redux/store";
import {addGlobalLinks, getGlobalLinks} from "../../redux/slices/nav-slice";
import {ComponentData} from "../component-renderer/componentRenderer";
import {getApi} from "../../services/api";

type SectionState = {
    open: boolean;
    loading?: boolean;
    contentRequest?: any;
    content?: ComponentData;
}

const initialState: SectionState = {
    open: false
}

const createSectionSlice = (sliceName: string) => createSlice({
    name: sliceName,
    initialState,
    reducers: {
        open: (state: SectionState, {payload}) => {
            state.open = true;
            state.content = payload
            addPath(state)
        },
        show: (state: SectionState) => {
            state.open = true;
        },
        close: (state: SectionState) => {
            state.open = false;
        },
        setContentRequest: (state: SectionState, {payload}) => {
            state.contentRequest = payload;
        },
        setLoading: (state: SectionState, {payload}) => {
            state.loading = payload;
        },
        setPartialState: (state: SectionState, {payload: {path, value}}) => {
            const currentElement = path ? get(state, path) : state
            for (const property in value) { //TODO merge without altering
                if (property !== 'path') {
                    currentElement[property] = value[property]
                }
            }
            addPath(state)
        }
    },
})

export const openSection = (section: StructuralSection) => (rel: string, method?: string, data?: any) => async (dispatch: AppDispatch, getState: GetState) => {
    const href = getGlobalLinks(getState())![rel]
    dispatch(section.actions.setLoading(true))
    dispatch(section.actions.setContentRequest({
        url: href,
        method,
        data,
    }))
    // @ts-ignore //TS is incorrectly inferring the type here,
    dispatch(section.actions.show())
    const sectionContent = await getApi()({
        method: method || (data ? "POST" : "GET"),
        url: href,
        data
    }).then(r => r.data)
    if (sectionContent.links) {
        dispatch(addGlobalLinks(sectionContent.links))
    }
    dispatch(section.actions.open(sectionContent))
    dispatch(section.actions.setLoading(false))
}

//TODO DRY with respect to open section
export const refreshSection = (section: StructuralSection) => async (dispatch: AppDispatch, getState: GetState) => {
    const {url, method, data} = section.selectors.getContentRequest(getState())
    dispatch(section.actions.setLoading(true))
    const sectionContent = await getApi()({
        method: method || (data ? "POST" : "GET"),
        url,
        data
    }).then(r => r.data)
    dispatch(section.actions.open(sectionContent))
    dispatch(section.actions.setLoading(false))
}

// export const refreshSection = (section: StructuralSection) => () => async (dispatch: AppDispatch, getState: GetState) => {
//     const href = getGlobalLinks(getState())![rel]
//     const sectionContent = await axios({
//         method: method || (data ? "POST" : "GET"),
//         url: href,
//         data
//     }).then(r => r.data)
//     if (sectionContent.links) {
//         dispatch(addGlobalLinks(sectionContent.links))
//     }
//     dispatch(section.actions.open(sectionContent))
// }

export const closeSection = (section: StructuralSection) => async (dispatch: AppDispatch, getState: GetState) => {
    // @ts-ignore //TS is incorrectly inferring the type here,
    dispatch(section.actions.close())
}

export const showSection = (section: StructuralSection) => async (dispatch: AppDispatch, getState: GetState) => {
    // @ts-ignore //TS is incorrectly inferring the type here,
    dispatch(section.actions.show())
}

export interface StructuralSection extends Slice<SectionState> {
    selectors: {
        getContent: (state: RootState) => ComponentData,
        isOpen: (state: RootState) => boolean,
        isLoading: (state: RootState) => boolean,
        getContentRequest: (state: RootState) => any
    }
}

export const createStructuralSection = (name: string): StructuralSection => {
    const slice = createSectionSlice(name);

    return {
        ...slice,
        selectors: {//TODO fix types
            // @ts-ignore
            getContent: (state: RootState) => state[name].content,
            // @ts-ignore
            isOpen: (state: RootState) => state[name].open,
            // @ts-ignore
            isLoading: (state: RootState) => state[name].loading,
            // @ts-ignore
            getContentRequest: (state: RootState) => state[name].contentRequest,
        }
    };
}

