import {createSelector, createSlice} from '@reduxjs/toolkit'
import {AppDispatch, GetState} from "../store";
import {fetchNavRoot} from "../../services/api";
import {getNavSlice} from "../base-selectors";
import {Link} from "../../components/OneTimePayment";
import {NavActionData} from "../../engine/navigation/useNavAction";
import {ComponentData} from "../../engine/component-renderer/componentRenderer";
import {QuickActionData, QuickActionMenuData} from "../../components/QuickActions";

export interface AppBarData {
    quickactions:  QuickActionMenuData;
    logo:          string;
    locales:       ComponentData;
    notifications: ComponentData;
    settings:      NavActionData;
    apps:          ComponentData;
    avatar:        ComponentData;
    links:         Link[];
}

type NavState = {
    nav: AppBarData,
    pdfViewerUrl: string
    globalLinks: { //TODO this is a WIP, but the idea is to eventually condensate all global navigaiton links here an maybe all links
        [rel: string]: string
    }
};

const initialState: Partial<NavState> = {
    globalLinks: {}
};

export const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        setNav: (state: Partial<NavState>,  {payload}) => {
            state.nav = payload;
            payload.links.forEach((l: Link) => state.globalLinks![l.rel] = l.href)
        },
        addGlobalLinks: (state: Partial<NavState>,  {payload}) => {
            payload.forEach((l: Link) => state.globalLinks![l.rel] = l.href)
        },
        showPdf: (state: Partial<NavState>,  {payload}) => {
            state.pdfViewerUrl = payload
        },
        closePdf: (state: Partial<NavState>) => {
            state.pdfViewerUrl = undefined
        },
    },
})

export const getNavRoot = () => async (dispatch: AppDispatch, getState: GetState) => {
    const navRoot = await fetchNavRoot();
    dispatch(setNav(navRoot))
}

//Selectors
const getNavData = createSelector(getNavSlice, (slice: Partial<NavState>) => slice?.nav)
export const getGlobalLinks = createSelector(getNavSlice, (slice: Partial<NavState>) => slice?.globalLinks || {})
const getPDF = createSelector(getNavSlice, (slice: Partial<NavState>) => slice?.pdfViewerUrl)

export const navSelectors = {
    getNavData, getGlobalLinks, getPDF
}

// Action creators are generated for each case reducer function
export const {setNav, showPdf, closePdf, addGlobalLinks} = navSlice.actions

export default navSlice.reducer