import {FC, useState} from "react";
import {Button, Card, styled, TextField} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import fromPairs from 'lodash/fromPairs';

export const AdvancedSearchContainer = styled(Card)`
  padding: 15px;
  width: calc(100% - 2 * 15px); //15px padding
  border-radius: 16px;
  position: absolute;
  z-index: 10;
`

export const SearchBottom = styled('div')`
  display: flex;
  justify-content: flex-end;
`

export const SearchField = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;

  > div {
    flex-grow: 1;
  }

  > label {
    display: flex;
    align-items: end;
    margin-right: 8px;
`

export const SearchButton = styled(Button)`
  background-color: rgb(237, 235, 242);
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 16px;
  padding: 4px 10px;
  text-transform: none;

  > *:first-child {
    margin-right: 4px;
  }
`


type AdvancedSearchProps = {
    fields: {
        data?: string;
        label: string,
        id: string,
        type: string,
    }[],
    onSearch: (params: any) => void
}

export const AdvancedSearch: FC<AdvancedSearchProps> = ({fields, onSearch}) => {
    const [fieldsData, setFieldsData] = useState(fromPairs(fields.map(f => [f.id, f.data])))

    const setFieldData = (fieldId: string) => (value: string) =>
        setFieldsData(allFields => ({...allFields, [fieldId]: value}))

    return (<AdvancedSearchContainer variant="outlined">
        {
            fields.map(f => <SearchField>
                <label>{f.label}</label>
                <TextField id={f.id} variant="standard" value={fieldsData[f.id]}
                           onChange={e => setFieldData(f.id)(e.target.value)}/>
            </SearchField>)
        }
        <SearchBottom><SearchButton variant="outlined" onClick={() => onSearch(fieldsData)}>
            <AccountCircleOutlinedIcon/>
            Search
        </SearchButton></SearchBottom>
    </AdvancedSearchContainer>)
}