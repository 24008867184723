import React, {FC, useEffect} from 'react';
import './App.css';
import {Menu, styled} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {AppDispatch} from "./redux/store";
import {useDispatch, useSelector} from "react-redux";
import {getNavRoot, navSelectors} from "./redux/slices/nav-slice";
import {FullscreenCenteredCircularProgress} from './components/blocks/FullscreenCenteredCircularProgress/FullscreenCenteredCircularProgress';
import {renderComponent} from "./engine/component-renderer/componentRenderer";
import {createBrowserRouter, Outlet, RouterProvider, useLocation} from "react-router-dom";
import values from "lodash/values";
import {baseRoutes} from "./App";
import {QuickActionData} from "./components/QuickActions";
import camelCase from "lodash/camelCase";
import {RouteObject} from "react-router";
import {openSection} from "./engine/structural-sections/structuralSectionsFactory";
import {StructuralSectionsMap} from "./engine/structural-sections/StructuralSectionsMap";
import {useSection} from "./engine/useSection";
import {CenteredCircularProgress} from "./components/blocks/CenteredCircularProgress/CenteredCircularProgress";

export const navBarHeight = '40px';

export const navBarPadding = '8px'

const NavBar = styled('nav')`
  position: relative;
  background: white;
  z-index: 1300; //Because drawer is 1200
  box-shadow: 0 4px 8px -8px gray;
  padding: ${navBarPadding};
  height: ${navBarHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavActions = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
    > * {
      margin-left: 24px;
      cursor: pointer;
    }
  > svg {
    scale: 0.85
  }

`

const LogoContainer = styled('img')`
  height: 28px;
`

const AppContainer = styled('div')`
  display:flex;
  width: 100%;
  height: 100%;
`

const AppContent = styled('div')`
  width: calc(100% -  ${navBarHeight} - 2 * ${navBarPadding});
  height: 100%;
  > :not(nav){
    overflow: scroll;
    width: 100%;
    height: calc(100% -  ${navBarHeight} - 2 * ${navBarPadding});
  }
`

export const DropDown = styled(Menu)`
    min-width: 100px;
`

export const useCurrentRoute  = () => {
    const location = useLocation();
    const nav = useSelector(navSelectors.getNavData)
    const currentPath = location.pathname.replace('/', '')

    return nav?.quickactions.quickactions.find(qa => qa.route.toLowerCase() === currentPath?.toLowerCase())
}

export const MainView: FC<{ children?: React.ReactNode }> = ({children}) => {
    const currentRoute = useCurrentRoute()
    const dispatch: AppDispatch = useDispatch()
    const {
        isLoading,
        renderContent,
    } = useSection(StructuralSectionsMap.MAIN)

    useEffect(() => {
        if(currentRoute?.rel){
            dispatch(openSection(StructuralSectionsMap.MAIN)(currentRoute?.rel))
        }
    },[currentRoute?.rel])

    return isLoading ? <CenteredCircularProgress/> : renderContent()
}

const buildRouter = (quickactions: QuickActionData[] = []) => {
    const dynamicRoutes = quickactions.map(qa => ({
        path: camelCase(qa.route),
        element: <MainView/>,
    })).reduce(
        (routesObject, currentRoute) => ({...routesObject, [currentRoute.path]: currentRoute}),
        {}
    )

    const routes = [
        {
            element: <AppLayout />,
            children: values({...baseRoutes, ...dynamicRoutes}) as RouteObject[],
        },
    ]

    return createBrowserRouter(routes);
}

export const AppNav: FC<{ children?: React.ReactNode }> = ({children}) => {
    const dispatch: AppDispatch = useDispatch()
    const nav = useSelector(navSelectors.getNavData)

    useEffect(() => {
        dispatch(getNavRoot())
    },[])

    const router = buildRouter(nav?.quickactions?.quickactions);

    return nav ? <AppContainer>
            <RouterProvider router={router}/>
    </AppContainer> : <FullscreenCenteredCircularProgress/>
}

export const AppLayout: FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const nav = useSelector(navSelectors.getNavData)
    const currentRoute = useCurrentRoute()

    useEffect(() => {
       dispatch(getNavRoot())
    },[])

    return nav ? <AppContainer>
        {renderComponent()(nav.quickactions, {currentPath: currentRoute?.route})}
        <AppContent>
            <NavBar>
                <LogoContainer src={nav.logo}/>
                <NavActions>
                    {renderComponent()(nav.locales)}
                    {renderComponent()(nav.notifications)}
                    <SettingsIcon/>
                    {renderComponent()(nav.apps)}
                    {renderComponent()(nav.avatar)}
                </NavActions>
            </NavBar>
            <Outlet/>
        </AppContent>
    </AppContainer> : <FullscreenCenteredCircularProgress/>
}
