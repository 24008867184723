import {styled} from "@mui/material";
import {FC} from "react";
import {SectionTitle} from "./blocks/shared.styles";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

const CardContainerSectionRoot = styled('div')`
  > * {
    margin-top: 8px;
  }
`

export interface CardContainerSectionData extends ComponentProps<CardContainerSectionData> {
    title: string;
    cards: ComponentData[];
}

export const CardContainerSection: FC<CardContainerSectionData> = ({title, cards, renderChild}) => {
    return <CardContainerSectionRoot>
        {title &&<SectionTitle>{title}</SectionTitle>}
        {cards.map(renderChild)}
    </CardContainerSectionRoot>
}