import {FC} from "react";
import {ComponentData} from "../engine/component-renderer/componentRenderer";
import {MuiGenericIcon} from "../utils/dynamic-icon";

export interface MuiIconData extends ComponentData {
    iconName: string
}

export const MuiIcon: FC<MuiIconData> = ({iconName}) => {
    return <MuiGenericIcon iconName={iconName}/>
}
