import {FC} from "react";
import {Button, styled} from "@mui/material";


export const StepNav = styled('div')`
  display: flex;
  justify-content: flex-end;
`

export const PrimaryButton = styled(Button)`
  font-weight: 800;
  color: rgb(90, 66, 122)
`

export const NextButton = PrimaryButton

export const CancelButton = styled(Button)`
  font-weight: 800;
`

export type StepNavProps = {
    onClose?: () => void;
    backLabel: string | undefined | null;
    nextLabel: string | undefined | null
};

export type StepNavigationData = StepNavProps & {
    navigateBack: (x: any) => void;
    navigateForward: (x: any) => void;
}

export const StepNavigation: FC<StepNavigationData> = ({navigateBack, navigateForward, backLabel, nextLabel}) => {
    return <StepNav>
        <CancelButton variant="text" onClick={navigateBack}>{backLabel || "Cancel"}</CancelButton>
        <NextButton variant="text" color={"primary"} onClick={navigateForward|| "Next"}>{nextLabel}</NextButton>
    </StepNav>;
}
