import {createSlice} from '@reduxjs/toolkit'
import {AppDispatch, GetState} from "../store";
import {getStepperSlice} from "../base-selectors";
import {getApi} from "../../services/api";
import {mapValues, replace} from 'lodash';
import {StepperProps} from "../stepper-data";
import {CyberSourceClient, CyberSourceKey, getCardDescription, getCardType} from "../../cybersource/CyberSource";
import {closeModalStepper} from "./hulabill-slice";

export enum StepperAction {
    NEXT= "Next",
    BACK =  "Back"
}

export type StepperState = Partial<StepperProps> & {
    loading: boolean,
}

const initialState: StepperState = {
    ["@type"]: "Stepper",
    loading: false
}

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        setStepper: (state: StepperState, {payload: {stepperContent}}) => {
            stepperContent["@type"] = "@Stepper"
            return stepperContent
        },
        setStepperExtraContent: (state: StepperState, {payload: extraContent}) => {
            state.stepExtraContent = extraContent
        },
        setLoading: (state: StepperState, {payload: loading}) => {
            state.loading = loading
        },
        removePaymentMethod: (state: StepperState) => {
            state.stepExtraContent.preferredPaymentMethod = null
        },
        setPaymentMethodError: (state: StepperState, {payload: error}) => {
            state.stepExtraContent.paymentMethodError = error
        }
    },
})

export const loadBaseStepper = (url: string, data: any) => async (dispatch: AppDispatch, getState: GetState) => {
    console.log("loadBaseStepper");
    await dispatch(setLoading(true))
    const stepperContent = await getApi().post(url, data).then(r => r.data);
    const paymentMethodUrl = stepperContent.links.find((l: any) => l.rel === "paymentmethod");
    if (paymentMethodUrl) {
        const paymentMethod = await getApi().get(paymentMethodUrl.href).then(r => r.data);    
        await dispatch(setStepper({stepperContent: {...stepperContent, paymentMethod}}))
    } else {
        await dispatch(setStepper({stepperContent: stepperContent}))
    }
    await dispatch(setLoading(false))
}

export const loadStepper = (url: string, data: any) => async (dispatch: AppDispatch, getState: GetState) => {    
    console.log("loadStepper");
    await dispatch(setLoading(true))
    const stepperContent = await getApi().post(url, data).then(r => r.data);
    const paymentMethodUrl = stepperContent.links.find((l: any) => l.rel === "paymentmethod")?.href
    const paymentMethod = await getApi().get(paymentMethodUrl).then(r => r.data);    
    await dispatch(setStepper({stepperContent: {...stepperContent, paymentMethod}}))
    await dispatch(setLoading(false))

}

export const loadStepperExtraContent = (rel: string) => async (dispatch: AppDispatch, getState: GetState) => {
    await dispatch(setLoading(true))
    const href = getStepperSlice(getState()).links?.find(l => l.rel === rel)?.href!
    const extraContent = await getApi().get(href).then(r => r.data);
    await dispatch(setStepperExtraContent(extraContent))
    await dispatch(setLoading(false))
}

export const navigate = (action: string, stepRawData: any, onClose?: () => void) => async (dispatch: AppDispatch, getState: GetState) => {
    if(action === StepperAction.BACK) {
        console.log("NAVIGATE BACK");

        dispatch(setLoading(true))
        const stepperState = getStepperSlice(getState());
        console.log("stepperState", stepperState)
    
        const activeStep = stepperState?.steps?.find(s => s.active)
        console.log("activeStep", activeStep);
    
        if(activeStep?.id === 1){
            onClose && onClose()
            return;
        }

        const newStepper = await getApi().post(activeStep!.postbackURL!, {}, {headers: {'Action': action,}}).then(r => r.data)
        console.log("newStepper", newStepper)
    
        dispatch(setStepper({stepperContent: newStepper}))
        dispatch(setLoading(false))

    } else {
        dispatch(setLoading(true))
        const stepperState = getStepperSlice(getState());    
        const activeStep = stepperState?.steps?.find(s => s.active)        
        const dataFormat = activeStep!.content.form.data;            
        const stepData = mapValues(dataFormat,(_, key) => stepRawData[key]);        
        const newStepper = await getApi().post(activeStep!.postbackURL!, stepData, {headers: {'Action': action,}}).then(r => r.data)    
        dispatch(setStepper({stepperContent: newStepper}))
        dispatch(setLoading(false))
        // if(action === StepperAction.NEXT && activeStep?.id === stepperState?.steps?.length){
        //     onClose && onClose();
        // }
    }
}

export const sendCardData = (paymentMethodData: any) => async (dispatch: AppDispatch, getState: GetState) => {
    const {
        number, name, month, year, cvv, zipCode
    } = paymentMethodData
    const stepperState = getStepperSlice(getState());
    const stepExtraContent = stepperState?.stepExtraContent;
    try{
        const authData: CyberSourceKey = {
            kid: stepExtraContent?.newPaymentMethods[0].tokenizer.authentication.jwk.kid,
            keystore: stepExtraContent?.newPaymentMethods[0].tokenizer.authentication.jwk,
        }
        const csResponse = await CyberSourceClient.tokenizePaymentMethod(authData, {
            cardType: getCardType(number) || '',
            cardNumber: number,
            securityCode: cvv || '',
            expiryMonth: month,
            expiryYear: year,
        })
        const paymentData = {
            type: 'card',
            last4: replace(number, /\s/g, '').substring(12, 16),
            description: getCardDescription(number),
            token: {
                data: csResponse,
                type: 'card'
            },
            expMonth: month,
            expYear: year,
            zipCode: zipCode,
            nameOnCard: name,
        }
        const preferredPaymentMethod: any = await getApi().post(stepExtraContent!.postback.postbackUrl, paymentData).then(r => r.data.preferredPaymentMethod)
        dispatch(navigate(StepperAction.NEXT,preferredPaymentMethod))
    } catch (e: any){
        dispatch(setPaymentMethodError(e.response?.data || e.responseStatus.message))
    }

}

export const sendBankData = (paymentMethodData: any) => async (dispatch: AppDispatch, getState: GetState) => {
    const {routing, name, account, address, city, state, zipCode} = paymentMethodData
    const stepperState = getStepperSlice(getState());
    const stepExtraContent = stepperState?.stepExtraContent;
    const paymentData = {
        type: 'bank',
        last4: account.substring(account.length - 4),
        description: "Checking",
        token: {
            data: {
                accountType: "checking",
                accountNumber: account,
                nameOnAccount: name,
                routingNumber: routing,
            },
            type: 'bank'
        },
        address: address,
        city: city,
        state: state,
        zipCode: zipCode,
    }
    try{
        const preferredPaymentMethod: any = await getApi().post(stepExtraContent!.postback.postbackUrl, paymentData).then(r => r.data.preferredPaymentMethod)
        dispatch(navigate(StepperAction.NEXT,preferredPaymentMethod))
    } catch (e: any){
        dispatch(setPaymentMethodError(e.response?.data))
    }
}

export const sendTextData = (text: string) => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(navigate(StepperAction.NEXT,text))
}

export const sendBooleanData = (value: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(navigate(StepperAction.NEXT,value))
}


// Action creators are generated for each case reducer function
export const {setStepper, setLoading, setStepperExtraContent, removePaymentMethod, setPaymentMethodError} = stepperSlice.actions

export default stepperSlice.reducer