import React, {FC, useState} from "react";
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid, MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import {Field, Form, SimpleField} from "../../redux/stepper-data";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {
    PaymentConfirmationGroup,
    PaymentConfirmationLabel,
    PaymentConfirmationMessage,
    PaymentConfirmationStatusMessage,
    PaymentConfirmationValue
} from "./Payment.styles";
import {navigate, sendBooleanData, sendTextData, StepperAction} from "../../redux/slices/stepper-slice";
import {StepNavigation, StepNavProps} from "./StepNavigation";
import {ExtraContentProps} from "./Stepper";
import {produce} from "immer";
import {DropDown} from "../../components/CreateContact";

export const StepperConfirmation: FC<Field & StepNavProps & ExtraContentProps> = ({rel, extraContent}) => {
    const confirmationData = extraContent

    return confirmationData && <div>
      <PaymentConfirmationStatusMessage>{confirmationData.statusMessage}</PaymentConfirmationStatusMessage>
      <PaymentConfirmationMessage>{confirmationData.confirmation.message}</PaymentConfirmationMessage>
      <PaymentConfirmationGroup>
        <PaymentConfirmationLabel>
            {confirmationData.amountLabel}
        </PaymentConfirmationLabel>
        <PaymentConfirmationValue>
            {confirmationData.amount}
        </PaymentConfirmationValue>
      </PaymentConfirmationGroup>
      <PaymentConfirmationGroup>
        <PaymentConfirmationLabel>
            {confirmationData.dateLabel}
        </PaymentConfirmationLabel>
        <PaymentConfirmationValue>
            {confirmationData.date}
        </PaymentConfirmationValue>
      </PaymentConfirmationGroup>
      <PaymentConfirmationGroup>
        <PaymentConfirmationLabel>
            {confirmationData.accountLabel}
        </PaymentConfirmationLabel>
        <PaymentConfirmationValue>
            {confirmationData.account}
        </PaymentConfirmationValue>
      </PaymentConfirmationGroup>
      <PaymentConfirmationGroup>
        <PaymentConfirmationLabel>
            {confirmationData.confirmationLabel}
        </PaymentConfirmationLabel>
        <PaymentConfirmationValue>
            {confirmationData.confirmationCode}
        </PaymentConfirmationValue>
      </PaymentConfirmationGroup>
    </div>
}

export const StepperTextField: FC<SimpleField> = ({id, title, value, setValue}) => {
    return <TextField
        variant={"standard"}
        placeholder={title}
        name={id}
        fullWidth
        value={value}
        onChange={e => setValue(e.target.value)}
    />
}

export const StepperDropDown: FC<SimpleField> = ({id, title, value, setValue, enum: options, items}) => {
    return <Select
        fullWidth
        label={title}
        value={value || (items.multiselect ? [] : null)}
        variant="standard"
        multiple={items.multiselect}
        onChange={(event: SelectChangeEvent<any>) => setValue(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
    >{
        (options).map((t: any) => <MenuItem value={t}>{t}</MenuItem>)
    }</Select>
}

export const StepperCheckBox: FC<SimpleField> = ({id, title, value, setValue}) => {
    return <>
        <Checkbox checked={value} onChange={(_, checked) => setValue(checked)}/>
        {title}
    </>
}

export const StepperRadioButton: FC<SimpleField> = ({id, title, value, setValue, enum: options}) => {
    return <RadioGroup value={value} onChange={ev => setValue(ev.target.value)}>
        {options.map((value: string) =>
            <FormControlLabel value={value} control={<Radio color="secondary"/>} label={value}/>
        )}
    </RadioGroup>
}
