import {createSlice} from '@reduxjs/toolkit'
import {AppDispatch, GetState} from "../store";
import {getApi} from "../../services/api";
import {addGlobalLinks} from "./nav-slice";
import {ConversationData} from "../../components/Conversation";

import pick from 'lodash/pick';

const SIGNIN_DATA = 'SIGNIN_DATA';
const CONVERSATION_KEY = 'CONVERSATION';
const CONVERSATION_URL_KEY = 'CONVERSATION_URL';

export const storeConversation = (conversation: any) => localStorage.setItem(CONVERSATION_KEY, JSON.stringify(conversation));
export const getConversation = () =>  JSON.parse(localStorage.getItem(CONVERSATION_KEY) || "{}");
export const clearConversation = () => localStorage.removeItem(CONVERSATION_KEY);
export const storeConversationUrl = (url: string) => localStorage.setItem(CONVERSATION_URL_KEY, url);
export const getConversationUrl = () => localStorage.getItem(CONVERSATION_URL_KEY) || "";
export const getSigninData = () => JSON.parse(localStorage.getItem(SIGNIN_DATA) || "{}");

export type ConversationState = Partial<ConversationData>;

const initialState: ConversationState = getConversation()

export const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        setConversation: (state: ConversationState, action) => {
            return action.payload
        }
    },
})

export const loadConversation = (rawContact: any) => async (dispatch: AppDispatch, getState: GetState) => {
    const url = rawContact.ConversationUrl.value;
    storeConversationUrl(url)
    const conversation: ConversationData = await getApi().get(url).then(r => r.data)
    dispatch(addGlobalLinks(conversation.links))
    dispatch(setConversation(conversation))
    storeConversation(conversation)
}

export const reloadConversation = () => async (dispatch: AppDispatch, getState: GetState) => {
    const url = getConversationUrl()
    const conversation: ConversationData = await getApi().get(url).then(r => r.data)
    dispatch(addGlobalLinks(conversation.links))
    dispatch(setConversation(conversation))
    storeConversation(conversation)
}

export const requestConversation = (conversationId: string) => async (dispatch: AppDispatch, getState: GetState) => {
    const signInData = getSigninData();    
    const requestURL = signInData.requests.conversation
    const rawContact: any = await getApi().get(requestURL + "/" + conversationId).then(r => r.data)
    const url = rawContact.ConversationUrl.value;
    storeConversationUrl(url)
    const conversation: ConversationData = await getApi().get(url).then(r => r.data)
    dispatch(addGlobalLinks(conversation.links))
    dispatch(setConversation(conversation))
    storeConversation(conversation)
}

export const closeConversation = () => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setConversation({}))
    clearConversation()
}

// Action creators are generated for each case reducer function
export const {setConversation} = conversationSlice.actions

export default conversationSlice.reducer