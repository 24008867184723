import {Hulabill} from "../../components/Hulabill";
import {HeaderedContainer} from "../../components/HeaderedContainer";
import {OneTimePayment} from "../../components/OneTimePayment";
import {PaymentPlan} from "../../components/PaymentPlan";
import {Stepper, StepperContainer} from "../../pages/Stepper/Stepper";
import {mapValues} from "lodash";
import {Conversation} from "../../components/Conversation";
import {AccountSearch} from "../../components/PatientAccountSearch/AccountSearch";
import {AccountExpansionCard} from "../../components/AccountExpansionCard";
import {GroupedValueCard} from "../../components/GroupedValueCard";
import {TabbedQuickActions} from "../../components/TabbedQuickActions";
import {ChipTabSection} from "../../components/ChipTabSection";
import {ExpansionTable} from "../../components/ExpansionTable";
import {ExpansionRow} from "../../components/ExpansionRow";
import {TransactionDetail} from "../../components/TransactionDetail";
import {CardContainerSection} from "../../components/CardContainerSection";
import {IconDescriptionExpansionCard} from "../../components/IconDescriptionExpansionCard/IconDescriptionExpansionCard";
import {SelectSection} from "../../components/SelectSection";
import {ExpansionPanel} from "../../components/ExpansionPanel";
import {ExpansionPanelContainer} from "../../components/ExpansionPanelContainer";
import {StatementCard} from "../../components/StatementCard";
import {SearchableCardContainer} from "../../components/SearchableCardContainer";
import {ServiceExpansionCard} from "../../components/ServiceExpansionCard";
import {ServiceDetailContainer} from "../../components/ServiceDetailContainer";
import {ServiceDetail} from "../../components/ServiceDetail";
import {ServiceCard} from "../../components/ServiceCard";
import {GroupedForms} from "../../components/GroupedForms";
import {DescriptionRowsContainer} from "../../components/DescriptionRowsContainer";
import {ActionCTA} from "../../components/NavAction";
import {QuickActionsMenu} from "../../components/QuickActions";
import {LocaleSelector} from "../../components/Locales";
import {NotificationMenu} from "../../components/Notifications";
import {AppSelector} from "../../components/Apps";
import {AvatarMenu} from "../../components/Avatar";
import {ActionableQuickActionsContainer} from "../../components/ActionableQuickActionsContainer";
import {TicketExpansionCard} from "../../components/TicketExpansionCard/TicketExpansionCard";
import {MuiIcon} from "../../components/MuiIcon";
import {BoldTwoColumn} from "../../components/BoldTwoColumn";
import {TextLines} from "../../components/TextLines";
import {Note} from "../../components/Note";
import {CreateContact} from "../../components/CreateContact";
import {AccountSearchForm} from "../../components/PatientAccountSearch/AccountSearchForm";
import {DataGridContainer} from "../../components/EnhancedDataGrid/DataGridContainer";
import {Home} from "../../pages/Home/Home";

const withProps = (extraProps: any) => (Component: any) => (props: any) => {
    const allProps = {...props, ...extraProps};
    return <Component {...allProps}/>
}

const withActions = (Component: any) => (props: any) => {
    const actions = props.actions || []
    const propsWithActions = mapValues(props, (value, key) => {
        const found = actions.find((a: any) => a.key === key);
        return found ? found.action : value;
    })
    return <Component {...propsWithActions}/>
}

export type RenderableComponentMap = {
    [componentName: string]: any;
}

export const ComponentMap: RenderableComponentMap = { //TODO check why this is not working with the bare blocks
    AccountExpansionCard: () => AccountExpansionCard,
    GroupedValueCard: () => GroupedValueCard,
    TabbedQuickActions: () => TabbedQuickActions,
    ChipTabSection: () => ChipTabSection,
    ExpansionTable: () => ExpansionTable,
    ExpansionTableNested: () => withProps({nested: true})(ExpansionTable),
    ExpansionRow: () => ExpansionRow,
    ExpansionRowNested: () => withProps({nested: true})(ExpansionRow),
    TransactionDetail: () => TransactionDetail,
    CardContainerSection: () => CardContainerSection,
    CardContainer: () => CardContainerSection,
    IconDescriptionExpansionCard: () => IconDescriptionExpansionCard,
    SelectSection: () => SelectSection,
    ExpansionPanel: () => ExpansionPanel,
    ExpansionPanelContainer: () => ExpansionPanelContainer,
    StatementCard: () => StatementCard,
    SearchableCardContainer: () => SearchableCardContainer,
    ServiceExpansionCard: () => withActions(ServiceExpansionCard),
    ServiceDetailContainer: () => ServiceDetailContainer,
    ServiceDetail: () => ServiceDetail,
    TicketDetail: () => ServiceDetail,
    TransactionExpansionCard: () => ServiceExpansionCard,
    TransactionDetailContainer: () => ServiceDetailContainer,
    ServiceCard: () => ServiceCard,
    GroupedForms: () => GroupedForms,
    DescriptionRowsContainer: () => DescriptionRowsContainer,
    Action: () => ActionCTA,
    HeaderedContainer: () => HeaderedContainer,
    PaymentOption: () => OneTimePayment,
    PaymentOptions: () => PaymentPlan,
    Stepper: () => StepperContainer,
    QuickActions: () => QuickActionsMenu,
    Locales: () => LocaleSelector,
    Notifications: () => NotificationMenu,
    Apps: () => AppSelector,
    Avatar: () => AvatarMenu,
    Conversation: () => Conversation,
    Hulabill: () => Hulabill,
    AccountSearch: () => AccountSearch,
    ActionableQuickActionsContainer: () => ActionableQuickActionsContainer,
    TicketExpansionCard: () => TicketExpansionCard,
    MuiIcon: () => MuiIcon,
    BoldTwoColumn: () => BoldTwoColumn,
    TextLines: () => TextLines,
    Note: () => Note,
    CreateContact: () => CreateContact,
    DatagridContainer: () => DataGridContainer,
    AccountSearchForm: () => AccountSearchForm,
    AgentDesk: () => Home
}

