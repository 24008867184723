import {DrawerContent} from "./styles"
import {CircularProgress, Drawer, styled} from "@mui/material";
import {StructuralSectionsMap} from "../StructuralSectionsMap";
import {useSection} from "../../useSection";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {useDispatch, useSelector} from "react-redux";
import {getStepperSlice} from "../../../redux/base-selectors";
import {navBarHeight, navBarPadding} from "../../../AppNav";
import {AppDispatch} from "../../../redux/store";
import {showSection} from "../structuralSectionsFactory";
import {FullscreenCenteredCircularProgress} from "../../../components/blocks/FullscreenCenteredCircularProgress/FullscreenCenteredCircularProgress";
import React from "react";
import {CenteredCircularProgress} from "../../../components/blocks/CenteredCircularProgress/CenteredCircularProgress";

const TogleAnchor = styled('div')<{drawerOpen?: boolean}>`
  position: absolute;
  top: calc(10px + ${navBarHeight} + 2 * ${navBarPadding});
  ${props => props.drawerOpen? `left: 0` : `right: 0`};
  height: 50px;
  display: flex;
  align-items: center;
`

const Toggle = styled('div')<{drawerOpen?: boolean}>`
  ${props => props.drawerOpen? `position: fixed` : ``};
  translate: ${props => props.drawerOpen? `-100%` : `0`};
  background: white;
  height: 50px;
  width: 30px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-right: none;
`

const CloseChevron = styled(ChevronRightOutlinedIcon)`
  cursor: pointer;
  z-index: 1;
`

export const DRAWER_WIDTH = 440

export const RightHandDrawer = () => {
    const {
        isOpen: isDrawerOpen,
        isLoading,
        close: closeDrawer,
        show,
        renderContent,
        content: drawerContent,
    } = useSection(StructuralSectionsMap.RIGHT_DRAWER)
    const {
        isOpen: isDialogOpen,
        isLoading: isDialogLoading,
        close: closeDialog,
        renderContent: renderContentInDialog,
        content: dialogContent,
    } = useSection(StructuralSectionsMap.DRAWER_DIALOG)

    const renderDrawerContent = () => isDialogOpen ?
        <DrawerContent>{renderContentInDialog({onClose: closeDialog})}</DrawerContent> :
        <DrawerContent padded>{renderContent()}</DrawerContent>

    const drawerVisible = isDrawerOpen || isDialogOpen;

    const hasContent = drawerContent || (isDialogOpen && dialogContent);

    const closeAll = () => {
        closeDrawer();
        closeDialog()
    }

    return <>
        <Drawer
            anchor={'right'}
            variant="persistent"
            open={drawerVisible}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box',
                    paddingTop: `calc(${navBarHeight} + 2 * ${navBarPadding})`
                },
            }}
        >
            <TogleAnchor drawerOpen><Toggle drawerOpen><CloseChevron onClick={closeAll}/></Toggle></TogleAnchor>
            {(isLoading ||  isDialogLoading) ?  <CenteredCircularProgress/> : renderDrawerContent()}
        </Drawer>
        {!drawerVisible && hasContent && <TogleAnchor><Toggle><CloseChevron onClick={show}/></Toggle></TogleAnchor>}
    </>
}
