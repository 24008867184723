import {FC} from "react";
import {ComponentData} from "../engine/component-renderer/componentRenderer";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import {styled} from "@mui/material";

export interface TextLinesData extends ComponentData {
    lines: string[];
}

export const TextLines: FC<TextLinesData> = ({lines}) => {
    return <>
        {lines.map((l,i) => <div key={i}>{l}</div>)}
    </>
}
