import React, {FC, useEffect} from "react";
// @ts-ignore
import amazonConnect from "./amazonConnectStreams"
import {styled} from "@mui/material";
import forIn from 'lodash/forIn'
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../redux/store";
import {loadConversation} from "../redux/slices/conversation-slice";
import ContactCallback = connect.ContactCallback;
import Contact = connect.Contact;
import {accountSelectors, loadSigninData} from "../redux/slices/account-slice";

const CCP_REGION = "us-east-1";
const CCP_CONTAINER_ID = "amazon-ccp-root"


type ContactCallbackMap = {
    [key: string]: ContactCallback
}

const initCCP = (ccpUrl: string, contactHandlers: ContactCallbackMap, onInit?: any) => {
    const container = document.getElementById(CCP_CONTAINER_ID);
    if(!container){
        throw new Error(`Container element with id ${CCP_CONTAINER_ID} not found`)
    }
    amazonConnect.connect.core.initCCP(
        container , {
            ccpUrl: ccpUrl, 	// REQUIRED
            loginPopup: false,				// optional, defaults to `true`
            loginPopupAutoClose: true,		// optional, defaults to `false`
            loginOptions: {                 // optional, if provided opens login in new window
                autoClose: true,              // optional, defaults to `false`
                height: 600,                  // optional, defaults to 578
                width: 400,                   // optional, defaults to 433
                top: 0,                       // optional, defaults to 0
                left: 0                       // optional, defaults to 0
            },
           loginUrl:"https://huladesk.us.auth0.com/samlp/ZnbVAaaFgiTOnigbEWrDhtryKPYFulOX?RelayState=https://us-east-1.console.aws.amazon.com/connect/federate/346b0456-5d63-4f62-9b14-536a2803a6c3?destination=%2Fconnect%2Fagent-app-v2",
            region: CCP_REGION,         // REQUIRED for `CHAT`, optional otherwise
            softphone: {                    // optional, defaults below apply if not provided
                allowFramedSoftphone: true,   // optional, defaults to false
                disableRingtone: false,       // optional, defaults to false
                ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
            },
            pageOptions: {                  // optional
                enableAudioDeviceSettings: true, // optional, defaults to 'false'
                enablePhoneTypeSettings: true // optional, defaults to 'true'
            },
            ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
            ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
            ccpLoadTimeout: 10000 //optional, defaults to 5000 (ms)
        });
    if(onInit){
        amazonConnect.connect.core.onInitialized(onInit)
    }
    amazonConnect.connect.core.onAccessDenied(() => console.log("AUTH FAILER ALKDKDHKIDIJD"))
    connect.contact(subscribeToContactEvents(contactHandlers))
    return amazonConnect.connect;
}

const subscribeToContactEvents = (handlers: ContactCallbackMap) => (contact: Contact & {[x: string]: any}) => {//Little type hack for indexing
    forIn(handlers, (handler, name) => {
        contact[name](handler)
    })
}

let initializedCCP: any;

const CCPPanel = styled('div')`
  background-color: black;
  height: calc(100% - 8px); /*Not really sure why we need to remove those extra 8px*/
  width: 400px;
`

export const AmazonCCP: FC<{onInit?:any, hidden?: boolean}> = ({onInit, hidden}) => {
    const dispatch: AppDispatch = useDispatch()
    const signInData = useSelector(accountSelectors.getSignInData)

    const contactHandlers = {
        onAccepted: (contact: Contact) => {
            dispatch(loadConversation(contact.getAttributes()))
        }
    }

    useEffect(() => {
        try{
            if(!initializedCCP){
                initializedCCP = initCCP(signInData?.connect.agent.ccpUrl, contactHandlers, onInit)
            }
        } catch (e) {
            console.error('Error initializing Amazon CCP', e)
        }
    },[])
    return <CCPPanel style={{visibility:hidden?'hidden': 'visible'}} id={CCP_CONTAINER_ID}/>
}