import {FC} from "react";
import {MuiGenericIcon} from "../../utils/dynamic-icon";
import {Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {ContentSection} from "../blocks/shared.styles";
import {GroupedFormsData} from "../GroupedForms";
import {useComponentState} from "../../engine/useOwnState";
import {AxiosError} from "axios";
import {getApi} from "../../services/api";
import {
    IconDescriptionExpansionCardContainer,
    UnexpandedContent,
    IconSection,
    ActionSection,
    ExpandedContent,
    ExpandMoreToggle
} from "./IconDescriptionExpansionCard.styles";
import {ComponentData, ComponentProps} from "../../engine/component-renderer/componentRenderer";
import {StructuralSectionsMap} from "../../engine/structural-sections/StructuralSectionsMap";
import {reloadConversation} from "../../redux/slices/conversation-slice";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {CenteredCircularProgress} from "../blocks/CenteredCircularProgress/CenteredCircularProgress";
import { useState } from "react";

export interface IconDescriptionExpansionCardData extends ComponentProps<IconDescriptionExpansionCardData> {
    expanded: boolean;
    icon: string; //Deprecated
    descriptions: string[]; //Deprecated
    leftAdornment: ComponentData,
    content: ComponentData[],
    details: ComponentData;
}

type IconDescriptionExpansionCardProps =
    IconDescriptionExpansionCardData
    & { actionIcon?: string, editable?: boolean, actionTooltip?: string, associate?: string};


export const IconDescriptionExpansionCard: FC<IconDescriptionExpansionCardProps> = ({
                                                                                        actionTooltip,
                                                                                        editable,
                                                                                        actionIcon,
                                                                                        icon,
                                                                                        leftAdornment,
                                                                                        content,
                                                                                        descriptions,
                                                                                        details,
                                                                                        expanded,
                                                                                        path,
                                                                                        associate,
                                                                                        renderChild,
                                                                                        closeSection
                                                                                    }) => {
    const [setComponentState] = useComponentState(StructuralSectionsMap.RIGHT_DRAWER, path)
    const dispatch: AppDispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    const editing = editable && expanded;

    const setExpanded = (editing: boolean) => {
        setComponentState({expanded: editing})
    }

    const toggleExpanded = () => setExpanded(!expanded)

    const setEditing = (editing: boolean) => setExpanded(editing)

    const submitData = async (details: GroupedFormsData, data: any) => {
        try {
            setLoading(true);
            const response = await getApi().post(details.postbackUrl, data)            

            setComponentState(response?.data)
            setComponentState({expanded: false})

            setLoading(false);            
        } catch (e) {
            if (e instanceof AxiosError) {
                setComponentState(e.response?.data)
            } else throw e;
        }
    }

    const submitAssociate = async (associate: string) => {
        try {
            setLoading(true);
            const response = await getApi().post(associate, {})
            dispatch(reloadConversation())
            setLoading(false);
            closeSection()
        } catch (e) {
            if (e instanceof AxiosError) {
                setComponentState(e.response?.data)
            } else throw e;
        }
    }

    return <IconDescriptionExpansionCardContainer variant={"outlined"}>
        <UnexpandedContent>
            <IconSection>{
                leftAdornment? renderChild(leftAdornment) : < MuiGenericIcon iconName={icon}/>
            }</IconSection>
            {
                editing ?
                    renderChild({
                            ...details,
                            onClose: () => setEditing(false),
                            onSave: (data: any) => submitData(details as GroupedFormsData, data)
                        } as ComponentData
                    ) :
                    <>
                        <ContentSection>
                            {content? content.map(renderChild) : descriptions.map(d => <div>{d}</div>)}
                        </ContentSection>
                        {actionIcon && <Tooltip title={actionTooltip}>                            
                          <ActionSection>{loading ? <CenteredCircularProgress/> : <MuiGenericIcon onClick={() => submitAssociate(associate as string)} iconName={actionIcon}/>}</ActionSection>
                        </Tooltip>}                        
                        <ActionSection iconSize={editable && '20px'}>
                            { editable ? <EditIcon onClick={() => setEditing(true)}/> :<ExpandMoreToggle onClick={toggleExpanded} toggled={expanded}/>}
                        </ActionSection>
                    </>
            }
        </UnexpandedContent>
        {(expanded && !editable) && <ExpandedContent>
            {renderChild(details)}
        </ExpandedContent>}
    </IconDescriptionExpansionCardContainer>;
}