import {FC} from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {Button, styled} from "@mui/material";
import {NavActionData, NavActionType, useNavAction} from "../engine/navigation/useNavAction";
import {MuiIcon} from "./MuiIcon";
import {MuiGenericIcon} from "../utils/dynamic-icon";

export const ActionButtonRoot = styled(Button)`
  text-transform: none;
  border-radius: 20px;

  > label {
    margin-left: 4px;
  }
`
export const LinkLikeAction = styled('div')`
  cursor: pointer;
  text-decoration: underline;
`

export const ActionCTA: FC<NavActionData> = (actionData) => {
    const {label, variant, icon, fillWidth} = actionData
    const doNavAction = useNavAction()

    return variant === NavActionType.LINK ?
        <LinkLikeAction onClick={() => {
            doNavAction(actionData)
        }}>{label}</LinkLikeAction> :
        <ActionButtonRoot variant="contained" fullWidth={fillWidth} onClick={() => {
            doNavAction(actionData)
        }}>
            <MuiGenericIcon iconName={icon}/>
            <label>{label}</label>
        </ActionButtonRoot>
}