import {FC, useEffect, useState} from "react";
import {ChipTab, ChipTabContainer} from "../../../components/Hulabill";
import {Field} from "../../../redux/stepper-data";
import {styled} from "@mui/material";
import {StepNavProps} from "../StepNavigation";
import {loadStepperExtraContent} from "../../../redux/slices/stepper-slice";
import {AppDispatch} from "../../../redux/store";
import {useDispatch} from "react-redux";
import {PaymentData} from "../../../redux/payment-data";
import {CardForm} from "./CardForm";
import {BankForm} from "./BankForm";
import {ExtraContentProps} from "../Stepper";
import {PaymentMethodError, SavedPaymentMethod} from "./SavedPaymentMethod";

export const CardFormRoot = styled('form')`
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;

  .MuiTextField-root {
    margin: 8px 0;
  }

  > div {
    display: flex;

    > * {
      flex: 1;

      :not(:last-child) {
        margin-right: 4px;
      }
    }
  }
`

export const PaymentMethodField: FC<Field & StepNavProps & ExtraContentProps> = ({
                                                                                   nextLabel,
                                                                                   backLabel,
                                                                                   extraContent,
                                                                                   onClose
                                                                               }) => {

    const [selectedPaymentTypeIndex, setSelectedPaymentTypeIndex] = useState(0)
    const forms: { [x: string]: any } = {
        "CreditCard": <CardForm nextLabel={nextLabel} backLabel={backLabel} onClose={onClose}/>,
        "Check": <BankForm nextLabel={nextLabel} backLabel={backLabel} onClose={onClose}/>
    }
    return (extraContent as PaymentData)?.preferredPaymentMethod?
        <SavedPaymentMethod {...(extraContent as PaymentData)} backLabel={backLabel} nextLabel={nextLabel} onClose={onClose} /> :
        <>
            {extraContent?.paymentMethodError && <PaymentMethodError>{extraContent.paymentMethodError}</PaymentMethodError>}
            <ChipTabContainer shaded>
                {(extraContent as PaymentData)?.newPaymentMethods.map((pm, i) =>
                    <ChipTab selected={selectedPaymentTypeIndex === i} label={pm.name}
                             onClick={() => setSelectedPaymentTypeIndex(i)}/>
                )}
            </ChipTabContainer>
            {extraContent?.newPaymentMethods[selectedPaymentTypeIndex].formType &&
            forms[extraContent?.newPaymentMethods[selectedPaymentTypeIndex].formType]
            }
        </>
}