import {FC, useState} from "react";
import {LinkRel, startPayment} from "../redux/slices/hulabill-slice";
import {Button, Card, styled, TextField} from "@mui/material";
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {DateTime} from "luxon";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import isEmpty from "lodash/isEmpty";
import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";

import {ComponentData} from "../engine/component-renderer/componentRenderer";
import {ModalType, useNavAction} from "../engine/navigation/useNavAction";

export const PaymentOptionCTA = styled(Button)`
  margin-top: 16px;
  border-radius: 16px;
  width: 100%;
  text-transform: none;
`

export const PaymentOptionContainer = styled(Card)`
  padding: 16px 16px 24px 16px;
  font-size: 13px;
  border-radius: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`

export const PaymentOptionHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 8px;
`

export const PaymentOptionTextFieldContainer = styled('div')`
  .MuiTextField-root {
    width: 90%;
  }

  svg {
    transform: translateY(10px);
    margin-right: 4px;
  }
`

export const PaymentOptionTextFieldHeader = styled('div')`
  margin: 16px 16px 0 16px;
  display: flex;
  justify-content: center;
`


export interface PaymentOptionData extends ComponentData {
    "@type": string;
    alerts: any[];
    greeting: null;
    balanceHeader: string;
    balanceDue: string;
    paymentDateHeader: string;
    defaultPaymentDate: string;
    allowedPaymentDateRange: number;
    dateErrorMessage: string;
    dateIcon: string;
    dateLocale: string;
    currencyIcon: string;
    selectionHeader: null;
    paymentOptions?: PaymentMethod[];
    paymentOption: PaymentMethod;
    postbackLabel: string;
    postbackUrl: string;
    links: Link[];
}

export const OneTimePayment: FC<PaymentOptionData> = ({
                                                          balanceHeader,
                                                          balanceDue,
                                                          paymentDateHeader,
                                                          defaultPaymentDate,
                                                          dateErrorMessage,
                                                          allowedPaymentDateRange,
                                                          dateIcon,
                                                          currencyIcon,
                                                          paymentOption,
                                                          postbackLabel,
                                                          postbackUrl
                                                      }) => {
    const [dateError, setDateError] = useState('')
    const [amountError, setAmountError] = useState('')
    const [date, setDate] = useState(DateTime.fromISO(defaultPaymentDate))
    const [amount, setAmount] = useState<number>(paymentOption.terms.defaultAmount)
    const doAction = useNavAction()
    const dispatch: AppDispatch = useDispatch()

    const handleAmountChange = (e: any) => {
        const newAmount = e.target.value;
        setAmount(newAmount);
        if (isNaN(newAmount) || parseFloat(newAmount) < parseFloat(paymentOption.terms.minAmount) || parseFloat(newAmount) > paymentOption.terms.maxAmount) {
            setAmountError(paymentOption.terms.amountErrorMessage)
        } else {
            setAmountError('')
        }
    }

    const proceedToPayment = () => {
        const data = {
            id: paymentOption.id,
            amount: amount,
            paymentDate: date
        }

        doAction({
            "@type": "@Action",
            "data": data,
            "rel": "postbackUrl",//TODO fix,
            "modalType": ModalType.DRAWER_DIALOG
        })

        // dispatch(startPayment(postbackUrl, data))
    }

    const hasErrors = !isEmpty(amountError) || !isEmpty(dateError)

    return <>
        <PaymentOptionContainer variant={'outlined'}>
            <PaymentOptionHeader>
                <span>{balanceHeader}</span>
                <span>{balanceDue}</span>
            </PaymentOptionHeader>
            <PaymentOptionTextFieldHeader>{paymentDateHeader}</PaymentOptionTextFieldHeader>
            <PaymentOptionTextFieldContainer>
                <MuiGenericIcon iconName={dateIcon}/>
                <MobileDatePicker
                    maxDate={DateTime.now().plus({days: allowedPaymentDateRange})}
                    minDate={DateTime.now().minus({days: allowedPaymentDateRange})}
                    onError={(error) => setDateError(error ? dateErrorMessage : '')}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            helperText: dateError,
                        },
                    }}
                    value={date}
                    onChange={d => d && setDate(d)}
                />
            </PaymentOptionTextFieldContainer>
            <PaymentOptionTextFieldHeader>{paymentOption.termsMarkup}</PaymentOptionTextFieldHeader>
            <PaymentOptionTextFieldContainer>
                <MuiGenericIcon iconName={currencyIcon}/>
                <TextField
                    error={!isEmpty(amountError)}
                    helperText={amountError}
                    value={amount}
                    onChange={handleAmountChange}
                    variant="standard"/>
            </PaymentOptionTextFieldContainer>
        </PaymentOptionContainer>
        <PaymentOptionCTA variant="contained" disabled={hasErrors}
                          onClick={proceedToPayment}>{postbackLabel}</PaymentOptionCTA>
    </>;
}

export interface PaymentMethod {
    id: string;
    name: string;
    type: string;
    termsMarkup: string;
    terms: Terms;
    data: Data;
}

export interface Data {
    id: string;
    amount: string;
    paymentDate: Date;
}

export interface Terms {
    defaultAmount: number;
    minAmount: string;
    maxAmount: number;
    amountErrorMessage: string;
}

export type Link = LinkRel