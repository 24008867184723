import {FC, useState} from "react";
import {produce} from "immer";
import {Button, Checkbox, MenuItem, Select, styled, TextField} from "@mui/material";
import {ComponentData} from "../engine/component-renderer/componentRenderer";
import {CenteredCircularProgress} from "./blocks/CenteredCircularProgress/CenteredCircularProgress";


export const GroupedFormsTitle = styled('div')`
  font-size: 16px;
  font-weight: 600;
  color: rgb(0, 0, 0, 0.6);
  height: 30px;
  display: flex;
  align-items: center;
`

export const GroupedFormsError = styled('div')`
  font-size: 12px;
  color: #ff3333;
`

export const GroupedFormsRoot = styled('div')`
  width: 100%;
`

export const GroupedFormsSection = styled('div')`
  :not(:first-child) {
    margin-top: 24px;
  }
`

export const CheckboxContainer = styled('div')`
  display: flex;
  margin-top: 24px;
  font-size: 12px;
  align-items: flex-start;
`

export const PhoneCheckboxContainer = styled('div')`
  display: flex;
  margin-top: 16px;
`

export const GroupedTextFieldRoot = styled(TextField)`
  width: calc(100% - 20px);
`

export const CustomCheckbox = styled(Checkbox)`
  padding: 0 8px 8px 0;
`

export const CustomButton = styled(Button)`
  color: rgb(0, 0, 0);
  font-weight: 600;
`

export const ActionsContainer = styled('div')`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`

type PhoneData = {
    type: string;
    value: string;
    primary: boolean
};

type PhonesFieldData = {
    types: string[],
    phones: PhoneData[]
    onChange: (value: any) => void
};

type FormFieldData = {
    label: string,
    id: string,
    data: string | boolean | PhonesFieldData,
    type: string,
    onChange: (value: any) => void;
};

export const PhonesSelect = styled(Select)`
`

export const PhonesFieldRoot = styled('div')`
  display: flex;
`

export const PhonesFieldTextInput = styled(GroupedTextFieldRoot)`
  > .MuiInputBase-root {
    padding-left: 10px;
  }
`

type GroupedFormsInitialState = {
    title: string;
    form: {
        label: string;
        id: string;
        data: string;
        type: string
    }[]
}[];

const getGroupedFormsInitialState = (groupedForms: GroupedFormsInitialState) => groupedForms
    .flatMap(gf => gf.form)
    .reduce((acc: any, form: any) => ({...acc, [form.id]: form.data}), {});


export interface GroupedFormsData extends ComponentData {
    groupedForms: {
        title: string,
        errorMessage: string,
        "form": {
            "label": string,
            "id": string,
            "data": string,
            "type": string
        }[]
    }[],
    cancelLabel: string,
    postbackLabel: string,
    postbackUrl: string
}

export const GroupedForms: FC<GroupedFormsData & { onClose: () => void, onSave: (data:any) => void }> = ({
                                                                                                             groupedForms,
                                                                                                             onClose,
                                                                                                             onSave,
                                                                                                             cancelLabel,
                                                                                                             postbackLabel,
                                                                                                             postbackUrl
                                                                                                         }) => {
    const [forms, setForms] = useState(getGroupedFormsInitialState(groupedForms))
    const [loading, setLoading] = useState(false);

    const setFormField = (fieldName: string) => (value: any) => setForms(produce((forms: any) => {
        
        forms[fieldName] = value
    }))

    const submit = () => {
        setLoading(true);
        onSave(forms)
    }

    return <GroupedFormsRoot>
        {groupedForms.map(({title,errorMessage, form}) => <GroupedFormsSection>
            {errorMessage && <GroupedFormsError>{errorMessage}</GroupedFormsError>}
            <GroupedFormsTitle>{title}</GroupedFormsTitle>
            {form.map(f => <FormField {...f} data={forms[f.id]} onChange={setFormField(f.id)}/>)}
        </GroupedFormsSection>)}
        {loading ? <ActionsContainer><CenteredCircularProgress /></ActionsContainer> :
            <ActionsContainer>
                <CustomButton onClick={onClose}>{cancelLabel}</CustomButton>
                <CustomButton onClick={submit}>{postbackLabel}</CustomButton>
            </ActionsContainer>
        }
    </GroupedFormsRoot>
}


export const FormField: FC<FormFieldData> = ({type, label, data, onChange}) => {
    //TODO refactor to be more declarative
    if (type === "textbox") {
        return (<GroupedTextFieldRoot variant={'standard'} label={label} value={data}
                                      onChange={event => onChange && onChange(event.target.value as string)}/>)
    }
    if (type === "checkbox") {
        return (<CheckboxContainer>
            <CustomCheckbox checked={data as boolean} onChange={(_, checked) => onChange(checked)}/>{label}
        </CheckboxContainer>)
    }
    if (type === "phones") {
        return <PhonesField {...(data as PhonesFieldData)} onChange={onChange}/> //Type safety can be improved with type predicates
    }
    throw 'There was an error rendering this component';
}


export const PhonesField: FC<PhonesFieldData> = ({types, phones, onChange}) => {
    const [selectedType, setSelectedType] = useState(types[0])

    const getSelectedPhone = () => phones.find(p => p.type === selectedType)

    const handlePhoneChange = ({
                                   number = getSelectedPhone()?.value,
                                   primary = getSelectedPhone()?.primary
                               }) => {
        onChange({
            types,
            phones: produce(phones, draft => {
                const selectedTypePhone = draft.find(p => p.type === selectedType);
                if (selectedTypePhone) {
                    selectedTypePhone.value = number || selectedTypePhone.value;
                    selectedTypePhone.primary = primary || selectedTypePhone.primary;
                } else {
                    draft.push({
                        type: selectedType,
                        value: number || "",
                        primary: primary || false
                    })
                }

            })
        })
    }

    const handleNumberChange = (number: string) => handlePhoneChange({number})

    const handlePrimaryChange = (primary: boolean) => handlePhoneChange({primary})

    return <div>
        <PhonesFieldRoot>
            <PhonesSelect
                variant={'standard'}
                value={selectedType}
                label="Age"
                onChange={event => setSelectedType(event.target.value as string)}
            >
                {types.map(t => <MenuItem value={t}>{t}</MenuItem>)}
            </PhonesSelect>
            <PhonesFieldTextInput
                variant={'standard'}
                value={getSelectedPhone()?.value ?? ""}
                onChange={event => handleNumberChange(event.target.value as string)}
            />
        </PhonesFieldRoot>
        <PhoneCheckboxContainer>
            <CustomCheckbox
                checked={getSelectedPhone()?.primary ?? false}
                onChange={(_, checked) => handlePrimaryChange(checked)}/>Primary
        </PhoneCheckboxContainer>
    </div>
}
