import {FC} from "react";
import {LinkRel} from "../redux/slices/hulabill-slice";
import {Card, styled} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import {TabbedQuickActionsProps} from "./TabbedQuickActions";
import {ComponentProps} from "../engine/component-renderer/componentRenderer";

const ModalRoot = styled('div')`
  height: 100%;
  width: 100%;
`

const ModalContent = styled('div')`
  margin: 16px;
`

const ModalHeader = styled(Card)((props) =>`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: ${props.theme.palette.primary.main};
  color: rgb(255,255,255);
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
   h1 {
    font-size: 18px;
    margin: 4px 0;
  }
  h2 {
    font-size: 14px;
    margin: 4px 0;
  }
  svg {
   cursor: pointer;
`)

const ModalHeaderText = styled('div')((props) =>`
`)

export interface HeaderedContainerData extends ComponentProps<HeaderedContainerData> {
    header: {
        label: string,
        descriptions: string[]
    },
    detailSliceName?: string,
    details?: TabbedQuickActionsProps,
    links: LinkRel[]
}

type HeaderedContainerProps = HeaderedContainerData & {onClose: () => void}

export const HeaderedContainer: FC<HeaderedContainerProps> = ({ header:{label, descriptions}, details, onClose, renderChild }) => {
    return <ModalRoot>
        <ModalHeader>
            <ModalHeaderText>
                <h1>{label}</h1>
                {descriptions.map(d => <h2>{d}</h2>)}
            </ModalHeaderText>
            <CancelIcon onClick={() => onClose()}/>
        </ModalHeader>
        <ModalContent>{renderChild(details)}</ModalContent>
    </ModalRoot>
}

