import React, {FC, useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import {styled} from "@mui/material";
import {navBarPadding} from "../AppNav";

import {ComponentData} from "../engine/component-renderer/componentRenderer";
import camelCase from "lodash/camelCase";
import {useNavigate} from "react-router-dom";


const MenuBar = styled('div')<{wide?: boolean}>`
  width:  ${props => props.wide ? '80px' : '60px'};
  padding: 0 ${navBarPadding};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MenuItem = styled('div')<{wide?: boolean, noMargin?: boolean}>`
  color: #444746;
  &:hover{
    color: #1f1f1f;
    svg {
        scale: 1.05
    }
  }
  flex-direction: column;
  cursor: pointer;
  height: 60px;
  margin: ${props => props.noMargin ? 0 : '4px 0'};
  display: flex;
  justify-content: space-around;
  align-items: center;
  > span {
    opacity: ${props => props.wide ? 1 : 0};
    transition: opacity 0.1s ease-in-out;
  }
`

const MenuItemLabel = styled('span')<{bold?: boolean}>`
  text-align: center;
  font-size: 14px;
  font-weight: ${props => props.bold ? 600 : 500}; ;
`

const MenuItemIconContainer = styled('div')<{selected?: boolean}>`
  background-color: ${props => props.selected ? 'rgb(201,231,253)' : 'transparent'};
  &:hover{
    background-color: ${props => props.selected ? 'rgb(201,231,253)' : 'rgb(230,232,237)'}
  }
  width: 56px;
  height: 32px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 16px;
`

export interface QuickActionData  extends ComponentData{
    label:   string;
    rel:     string;
    variant: string;
    icon:    string;
    route:   string;
}

export interface QuickActionMenuData extends ComponentData {
    currentPath?: string
    icon: string;
    quickactions: QuickActionData[];
}

export const QuickActionsMenu: FC<QuickActionMenuData> = ({currentPath, icon, quickactions}) => {
    const [wide, setWide] = useState(false)
    const navigate = useNavigate();

    return <MenuBar wide={wide}>
        <MenuItem noMargin>
            <MenuIcon onClick={() => setWide(w => !w)}/>
        </MenuItem>
        {quickactions.map((qa) => {
            const selected = qa.route.toLowerCase() === currentPath?.toLowerCase()
            return <MenuItem wide={wide} onClick={() => navigate(`/${camelCase(qa.route)}`)}>
                <MenuItemIconContainer selected={selected}>{selected ?
                    <MuiGenericIcon iconName={qa.icon}/>
                    : <MuiGenericIcon iconName={`${qa.icon}Outlined`} />
                }</MenuItemIconContainer>
                <MenuItemLabel bold={selected}>{qa.label}</MenuItemLabel>
            </MenuItem>
        })}
    </MenuBar>;
}