import {FC, useState} from "react";
import {InputAdornment, TextField} from "@mui/material";
import {MuiGenericIcon} from "../../../utils/dynamic-icon";
import {StepNavigation, StepNavProps} from "../StepNavigation";
import {navigate, sendBankData, sendCardData, StepperAction} from "../../../redux/slices/stepper-slice";
import {CardFormRoot} from "./PaymentMethodField";
import {AppDispatch} from "../../../redux/store";
import {useDispatch} from "react-redux";


export const BankForm: FC<StepNavProps> = ({nextLabel, backLabel, onClose}) => {
    const dispatch: AppDispatch = useDispatch()
    const [name, setName] = useState('')
    const [routing, setRouting] = useState('')
    const [account, setAccount] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')

    const navigateForward = () => dispatch(sendBankData({routing, name, account, address, city, state, zipCode}))

    const navigateBack = () => dispatch(navigate(StepperAction.BACK, null, onClose))

    return <>
        <CardFormRoot>
            <TextField variant={"standard"} placeholder={"Name on Bank Account"} fullWidth value={name}
                       onChange={e => setName(e.target.value)}
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><MuiGenericIcon
                               iconName={"AccountBox"}/></InputAdornment>,
                       }}
            />
            <TextField variant={"standard"} placeholder={"Routing Number"} fullWidth value={routing}
                       onChange={e => setRouting(e.target.value)}
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><MuiGenericIcon
                               iconName={"AccountBalance"}/></InputAdornment>,
                       }}
            />
            <TextField variant={"standard"} placeholder={"Account Number"} fullWidth value={account}
                       onChange={e => setAccount(e.target.value)}
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><MuiGenericIcon
                               iconName={"AccountBox"}/></InputAdornment>,
                       }}
            />
            <TextField variant={"standard"} placeholder={"Address"} fullWidth value={address}
                       onChange={e => setAddress(e.target.value)}
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><MuiGenericIcon
                               iconName={"HomeOutlined"}/></InputAdornment>,
                       }}
            />
            <div>
                <TextField variant={"standard"} placeholder={"City"} fullWidth value={city}
                           onChange={e => setCity(e.target.value)}/>
                <TextField variant={"standard"} placeholder={"State"} fullWidth value={state}
                           onChange={e => setState(e.target.value)}/>
                <TextField variant={"standard"} placeholder={"Zip Code"} fullWidth value={zipCode}
                           onChange={e => setZipCode(e.target.value)}/>
            </div>
        </CardFormRoot>
        <StepNavigation nextLabel={nextLabel} backLabel={backLabel} navigateForward={navigateForward} navigateBack={navigateBack}/>
    </>
}