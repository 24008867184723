import {Card, styled} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const SectionTitle = styled('div')<{noTopMargin?: boolean}>`
  margin: ${props => props.noTopMargin? '0 0 24px 0' : '24px 0'};
  font-size: 18px;
  color: rgb(67, 67, 91);
`

export const ContentSection = styled('div')`
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const ExpansionDetailsContainer = styled('div')<{ open?: boolean }>`
  //transition: max-height 1s ease-in-out;
  overflow: hidden;
  ${props => !props.open ? 'max-height: 0;' : 'max-height: 1200px;'};
`

export const ExpandIcon = styled(KeyboardArrowDownIcon)<{ open?: boolean }>`
  cursor: pointer;
  transition: rotate 1s ease-in-out;
  ${props => props.open ? 'rotate: 180deg' : ''};
`

export const ExpandIconPlaceholder = styled(ExpandIcon)`
  visibility: hidden;
`

export const ServiceDetailRecordContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`

export const ServiceDetailRoot = styled('div')`
  :not(:first-child) {
    margin-top: 16px;
  }
`

export const ServiceDetailTitle = styled('div')`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
`

export const StatementCardRoot = styled(Card)`
  border-radius: 16px;
`

export const StatementCardContent = styled('div')`
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
`

export const StatementCardRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  font-size: 13px;

  :last-child {
    font-weight: 600;
  }
`

export const expansionTableSideMargin = '16px';

export const TableRow = styled('div')<{ nested?: boolean }>`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0 ${props => props.nested ? `calc(${expansionTableSideMargin} - 4px)` : expansionTableSideMargin};
  font-size: 13px;
`

export const ExpansionRowColumns = styled(TableRow)``

export const ExpansionRowContent = styled('div')`
  padding-top: 16px;
  padding-bottom: 16px;
`

export const ExpansionTableCard = styled(Card)`
  border-radius: 16px;
`

export const TableCell = styled('div')<{ flexProportion?: number, pointer?: boolean }>`
  flex: ${props => props.flexProportion ? props.flexProportion : '1'};
  cursor: ${props => props.pointer ? 'pointer' : 'auto'};
  text-decoration: ${props => props.pointer ? 'underline' : 'none'};
  :not(:first-child) {
    padding-left: 16px;
  }
`

export const ExpansionRowContainer = styled('div')`
  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`

