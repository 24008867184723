import { configureStore } from '@reduxjs/toolkit'
import conversationSlice from './slices/conversation-slice'
import accountSlice from "./slices/account-slice";
import hullabillslice from "./slices/hulabill-slice";
import stepperSlice from "./slices/stepper-slice";
import navSlice from "./slices/nav-slice";

import {StructuralSectionsMap} from "../engine/structural-sections/StructuralSectionsMap";

export const store = configureStore({
    reducer: {
        MAIN: StructuralSectionsMap.MAIN.reducer, //TODO try to automatically wire by name
        RIGHT_DRAWER: StructuralSectionsMap.RIGHT_DRAWER.reducer, //TODO try to automatically wire by name
        DRAWER_DIALOG: StructuralSectionsMap.DRAWER_DIALOG.reducer, //TODO try to automatically wire by name
        DIALOG: StructuralSectionsMap.DIALOG.reducer, //TODO try to automatically wire by name
        conversation: conversationSlice,
        account: accountSlice,
        hulabill: hullabillslice,
        stepper: stepperSlice,
        nav: navSlice
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type GetState = typeof store.getState