import {FC} from "react";
import {IconDescriptionExpansionCard, IconDescriptionExpansionCardData} from "./IconDescriptionExpansionCard/IconDescriptionExpansionCard";

import {GroupedValueCardData} from "./GroupedValueCard";

export interface AccountExpansionCardData extends Omit<IconDescriptionExpansionCardData, 'icon'> {
    descriptions: string[];
    tooltip: string;
    details: GroupedValueCardData;
}

export const AccountExpansionCard: FC<AccountExpansionCardData> = (props) => {
    return <IconDescriptionExpansionCard icon='AccountCircleOutlined' actionIcon='PlaylistAdd' {...props}/>
}
