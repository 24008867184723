import React, {FC} from "react";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import {MenuItem as MUIMenuItem} from "@mui/material";
import {DropDown} from "../AppNav";

import {ComponentData} from "../engine/component-renderer/componentRenderer";

export interface LocaleSelectorData extends ComponentData {
    icon: string;
    locales: Locale[];
}

export interface Locale {
    label: string;
    locale: string;
}

export const LocaleSelector: FC<LocaleSelectorData> = ({icon, locales}) =>{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <MuiGenericIcon iconName={icon} onClick={handleClick}/>
            <DropDown
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {locales.map(l => <MUIMenuItem onClick={handleClose}>{l.label}</MUIMenuItem>)}
            </DropDown>
        </div>
    );
}