import {styled, TextField} from "@mui/material";
import React, { FC } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from '@mui/icons-material/Done';
import {GridView} from "./GridViewModel";

const ViewTitle = styled('div')`
    display: flex;
  align-items: center;
`

const StyledEditIcon = styled(EditIcon)`
    scale: 1.2;
    margin-left: 24px;
    cursor: pointer;
`

const StyledDoneIcon = styled(DoneIcon)`
    scale: 1.5;
    margin-left: 24px;
    cursor: pointer;
`

const EditViewTextField = styled(TextField)`
  margin-bottom: 12px;
  
  .MuiInputBase-input {
    font-size: 32px !important;
    font-weight: 700 !important;
    margin: 22px 0 10px 0 !important;
  }
`

export const GridHeader: FC<{currentView: GridView, onViewNameChange: (name:string) => void}> = ({currentView, onViewNameChange}) => {
    const [editingViewTitle, setEditingViewTitle] = React.useState<boolean>(false);

    return <ViewTitle>
        {editingViewTitle?
            <>
                <EditViewTextField
                    variant={'standard'}
                    value={currentView.title}
                    onChange={(event: any) => onViewNameChange(event.target.value as string)}
                />
                <StyledDoneIcon onClick={() => setEditingViewTitle(false)}/>
            </>
            : <>
                <h1>{currentView.title}</h1>
                <StyledEditIcon onClick={() => setEditingViewTitle(true)}/>
            </>
        }
    </ViewTitle>
}