import React, {useEffect} from "react";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {logout} from "../../redux/slices/account-slice";
import {CCP_LOGOUT_URL} from "../../config";

export const Logout = () => {
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        dispatch(logout())
    }, [])

    return <iframe hidden={true} src={CCP_LOGOUT_URL}/>;
};

