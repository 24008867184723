import {FC} from "react";
import {AppDispatch} from "../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {navSelectors, showPdf} from "../redux/slices/nav-slice";
import {StatementCardContent, StatementCardRoot, StatementCardRow} from "./blocks/shared.styles";
import {styled} from "@mui/material";

import {ComponentData} from "../engine/component-renderer/componentRenderer";

export const StatementCardHeader = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 16px
`

export const StatementCardFooter = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px
`

export const StatementCardQuickAction = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: rgb(67,67,91);
  padding: 16px;
  cursor: pointer;
`

export interface StatementCardData extends ComponentData {
    header: string,
    rows: {
        description: string,
        amount: string
    }[],
    footer: string,
    quickAction: {
        "icon": string,
        "label": string,
        "rel": string,
        "route": string,
        "variant": string,
    }
}

export const StatementCard: FC<StatementCardData> = ({header, rows, footer, quickAction}) => {
    const dispatch: AppDispatch = useDispatch()
    const globalLinks = useSelector(navSelectors.getGlobalLinks)

    const openPdf = () => {dispatch(showPdf(globalLinks![quickAction.rel]))};

    return <StatementCardRoot variant={'outlined'}>
        <StatementCardContent>
            <StatementCardHeader>{header}</StatementCardHeader>
            {rows.map(r => <StatementCardRow>
                <div>{r.description}</div>
                <div>{r.amount}</div>
            </StatementCardRow>)}
            <StatementCardFooter>{footer}</StatementCardFooter>
        </StatementCardContent>
        <StatementCardQuickAction onClick={openPdf}>{quickAction.label}</StatementCardQuickAction>
    </StatementCardRoot>
}