import {isDraft} from "immer";
import {original} from "@reduxjs/toolkit";

//TODO Add tests
export const addPath = (obj, prevPath = '') => {//TODO needs better refactor for nested arrays and similar edge-cases
    if(isObject(obj)){
        if(Array.isArray(obj)){
            obj.forEach((c,i) => {
                addPath(c, `${prevPath}[${i}]`)
            })
        } else {
            obj.path = prevPath;
            Object.keys(obj).forEach(key => {
                addPath(obj[key], prevPath ? `${prevPath}.${key}` : key)
            })
        }
    }
}

const isObject = (o) => o && typeof getOriginal(o) === 'object'

const getOriginal = (o) => isDraft(o) ? original(o) : o
