export const reorder = (array: any[], oldIndex: number, targetIndex: number): any[] => {
    if(oldIndex < targetIndex){
        const firstBit = array.slice(0, oldIndex)
        const movedElement = array[oldIndex]
        const middleBit = array.slice(oldIndex + 1, targetIndex+1)
        const lastBit = array.slice(targetIndex + 1)
        return [...firstBit, ...middleBit, movedElement, ...lastBit ]
    } else if(oldIndex > targetIndex){
        const firstBit = array.slice(0, targetIndex)
        const movedElement = array[oldIndex]
        const middleBit = array.slice(targetIndex, oldIndex)
        const lastBit = array.slice(oldIndex + 1)
        return [...firstBit, movedElement, ...middleBit, ...lastBit ]
    } else {
        return array
    }
}