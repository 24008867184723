import {Card, styled} from "@mui/material";
import {FC} from "react";

import {ComponentData} from "../engine/component-renderer/componentRenderer";

const TransactionDetailsContainer = styled(Card)`
  border-radius: 8px;
  padding: 8px;
  font-size: 13px;
`

const TransactionDetailsHeader = styled('div')`
    margin-bottom: 8px;
`

const TransactionDetailsRecord = styled('div')`
  color: rgba(0,0,0,0.5);
  display:flex;
  justify-content: space-between;
`

export interface TransactionDetailData extends ComponentData {
    header: string;
    records: {
        description: string;
        value: string;
    }[]
}

export const TransactionDetail: FC<TransactionDetailData> = ({header, records}) => {
    return <TransactionDetailsContainer variant='outlined'>
        <TransactionDetailsHeader>{header}</TransactionDetailsHeader>
        {records.map(r => <TransactionDetailsRecord>
            <div>{r.description}</div>
            <div>{r.value}</div>
        </TransactionDetailsRecord>)}
    </TransactionDetailsContainer>
}
