import React, {useEffect} from "react";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {getAuthCode} from "../../redux/slices/account-slice";

export const Login = () => {
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        dispatch(getAuthCode())
    }, [])
    return null;
}
