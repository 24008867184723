import React, {useEffect} from "react";
import {AmazonCCP} from "../../amazon/AmazonCCP";
import {baseRoutes} from "../../App";
import {AppDispatch} from "../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {accountSelectors, loadCredentials} from "../../redux/slices/account-slice";
import {FullscreenCenteredCircularProgress} from "../../components/blocks/FullscreenCenteredCircularProgress/FullscreenCenteredCircularProgress";

export const LoginSuccess = () => {
    const dispatch: AppDispatch = useDispatch()
    const {connect} = useSelector(accountSelectors.getSignInData)
    useEffect(() => {
        dispatch(loadCredentials())
    }, [])

    return <>
        <FullscreenCenteredCircularProgress/>
        <AmazonCCP onInit={() => {
            window.location.href = baseRoutes.Home.path as string
        }}/>
        <iframe hidden={true} src={connect.agent.authorization}/>
    </>;
};
