const randomStr = (len: number) => {
    const arr = new Uint8Array(len);
    return window.crypto.getRandomValues(arr);
}

export const createPKCEpair = async () => {
    const verifier = bufferToBase64UrlEncoded(randomStr(32));
    const challenge = bufferToBase64UrlEncoded(await sha256(verifier));
    return {verifier, challenge}
}

const sha256 = (message: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return window.crypto.subtle.digest('SHA-256', data);
}

const bufferToBase64UrlEncoded = (input: ArrayBuffer) => {
    const bytes = new Uint8Array(input);
    return urlEncodeBase64(window.btoa(String.fromCharCode(...bytes)));
}

const urlEncodeBase64 = (input: string) => input.replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');




