import {createStructuralSection, StructuralSection} from "./structuralSectionsFactory";

interface StructuralSectionsMapObject {
    MAIN: StructuralSection
    RIGHT_DRAWER: StructuralSection
    DRAWER_DIALOG: StructuralSection
    DIALOG: StructuralSection
}

export const StructuralSectionsMap: StructuralSectionsMapObject = {
    MAIN: createStructuralSection('MAIN'),
    RIGHT_DRAWER: createStructuralSection('RIGHT_DRAWER'),
    DRAWER_DIALOG: createStructuralSection('DRAWER_DIALOG'),
    DIALOG: createStructuralSection('DIALOG')
}