import React, {FC} from "react";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from "@mui/x-data-grid-premium";
import {Box, Button, styled, Tooltip} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  button {
    min-width: 0;
  }
`

type EnhancedGridToolbarProps = {
    showQuickSearch?: boolean
    onDelete: () => void,
    onDuplicateCurrent: () => void,
    onSaveCurrent?: () => void
};

export const EnhancedGridToolbar: FC<EnhancedGridToolbarProps> = ({onDelete, onDuplicateCurrent, onSaveCurrent, showQuickSearch}) => {
    return (
        <StyledGridToolbarContainer>
            {showQuickSearch && <GridToolbarQuickFilter/>}
            <Box sx={{flexGrow: 1}}/>
            {onSaveCurrent && <Tooltip title="Save current view">
                <Button onClick={onSaveCurrent}><SaveIcon/></Button>
            </Tooltip>}
            <Tooltip title="Make a copy of this view">
                <Button onClick={onDuplicateCurrent}><ContentCopyIcon/></Button>
            </Tooltip>
            <Tooltip title="Delete selected rows">
                <Button onClick={onDelete}><DeleteIcon/></Button>
            </Tooltip>
            <GridToolbarExport/>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
        </StyledGridToolbarContainer>
    );
}