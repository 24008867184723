import axios from "axios";
import {API_BASE_URL, APP_DOMAIN} from "../config";
import {baseRoutes} from "../App";

export const api = axios.create({
    baseURL: API_BASE_URL,
});

export const getApi = () => axios.create({
    headers: {
        'Authorization': localStorage.getItem('idToken'),
        'X-Access-Token': localStorage.getItem('accessToken'),
        'X-Refresh-Token': localStorage.getItem('refreshToken')
    }
});

export const fetchCredentials = (cognitoSignInData: any) => async (authCode: string, codeVerifier: string|null) => {
    console.log("fetchCredentials")
    return axios.post(cognitoSignInData.tokenEndpoint,
        {
            code: authCode,
            client_id: cognitoSignInData.clientId,
            redirect_uri: `${APP_DOMAIN}${baseRoutes.LoginSuccess.path}`,
            grant_type: 'authorization_code',
            code_verifier: codeVerifier
        }).then(r => r.data)
}

export const fetchSignInData = () => {
    return axios.get(API_BASE_URL +  "/signin").then(r => {
        console.log('SIGNIN DATA',JSON.stringify(r.data))
        return r.data
    })
}

export const fetchNavRoot = () => {
    return getApi().get(API_BASE_URL +  "/navroot").then(r => r.data)
}