import {styled} from "@mui/material";
import {DataGridPremium as DataGrid} from '@mui/x-data-grid-premium';

export const EnhancedGridRoot = styled('div')`
  display: flex;
  height: 100%;
`

export const GridContent = styled('div')`
  padding: 16px 16px 16px 48px;
  width: 1000px; //TODO make 100%

  > h1 {
    font-size: 24px;
  }
`

export const StyledDataGrid = styled(DataGrid)`
  border: none;

  .MuiDataGrid-groupingCriteriaCellToggle {
    display: none;
  }

  .MuiDataGrid-groupingCriteriaCell {
    translate: -10px;
  }

`