import {FC, useState} from "react";
import isEmpty from "lodash/isEmpty";
import {
    ExpandIcon,
    ExpandIconPlaceholder,
    ExpansionDetailsContainer,
    ExpansionRowColumns,
    ExpansionRowContainer,
    ExpansionRowContent,
    expansionTableSideMargin,
    TableCell
} from "./blocks/shared.styles";
import {getColumnProportion} from "./blocks/shared.utils";
import {styled} from "@mui/material";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export const ExpansionRowSummary = styled('div')`
  margin: 4px 12px 0 12px;
  color: rgba(0, 0, 0, 0.6)
`
export const ExpansionRowDetails = styled('div')<{ open?: boolean }>`
  background-color: rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: ${expansionTableSideMargin};
`

export interface ExpansionRowData extends ComponentProps<ExpansionRowData> {
    columns: string[];
    summary?: string | null;
    details?: ComponentData;
}

export const ExpansionRow: FC<ExpansionRowData & { nested?: boolean }> = ({nested, columns, summary, details, renderChild}) => {
    const [open, setOpen] = useState(false)
    return <ExpansionRowContainer>
        <ExpansionRowContent>
            <ExpansionRowColumns nested={nested}>
                {columns.map((c, i) =>
                    <TableCell flexProportion={getColumnProportion(nested ? 'nested' : 'root', i)}>{c}</TableCell>
                )}
                {isEmpty(details) ? <ExpandIconPlaceholder/> :
                    <ExpandIcon open={open} onClick={() => setOpen(o => !o)}/>}
            </ExpansionRowColumns>
            {summary && <ExpansionRowSummary>Online Payment - Amex-2345</ExpansionRowSummary>}
        </ExpansionRowContent>
        {details &&
          <ExpansionDetailsContainer open={open}>
            <ExpansionRowDetails open={open}>
                {renderChild(details)}
            </ExpansionRowDetails>
          </ExpansionDetailsContainer>}
    </ExpansionRowContainer>
}
