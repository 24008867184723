import {FC, ReactNode} from "react";
import {useComponentState} from "../useOwnState";
import {closeSection, StructuralSection} from "../structural-sections/structuralSectionsFactory";
import {LinkRel} from "../../redux/slices/hulabill-slice";
import {produce} from "immer";
import {ComponentMap} from "./ComponentMap";
import {cloneDeep} from "lodash";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";

export interface ComponentData {
    "@type": string;
    path: string;
    links?: LinkRel[]
}

export interface ComponentProps<OwnData extends ComponentData> extends ComponentData {
    setComponentState: (newState: Partial<OwnData>) => void,
    mutateComponentState: (producer: (newState: OwnData) => void) => void,
    renderChild: (data: ComponentData | string | undefined, extraProps?: any) => ReactNode,
    closeSection: () => void;
}

export const renderComponent = (defaultComponent?: FC<any>) => (data: ComponentData | string | undefined, extraProps?: any) => {
    if (!data) return null;
    if (typeof data === 'string') {
        return <>{data}</>
    }
    const componentName = data["@type"]?.substring(1);
    const Component = (ComponentMap[componentName] && ComponentMap[componentName]()) || defaultComponent || (() =>
        <div>Component under construction</div>)
    if (!Component) {
        throw new Error(`No component found for ${componentName} and no default component was provided`)
    }
    return <Component {...data} {...extraProps}/>
}

export const renderComponentTree = (section: StructuralSection) => (data: ComponentData, extraProps?: any) => {
    const [setComponentState] = useComponentState(section, data?.path)
    const dispatch: AppDispatch = useDispatch()

    const mutateComponentState = (producer: (newState: ComponentData) => void) => {
        setComponentState(cloneDeep(produce(data, producer)))
    }

    return renderComponent()(data, {
        setComponentState,
        mutateComponentState,
        renderChild: renderComponentTree(section),
        // @ts-ignore //TS is incorrectly inferring the type here,
        closeSection: () => dispatch(section.actions.close()),
        ...extraProps
    })
}
