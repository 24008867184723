import {FC, useState} from "react";
import {ComponentProps} from "../engine/component-renderer/componentRenderer";
import {Button, styled, TextField} from "@mui/material";
import {getApi} from "../services/api";
import {CenteredCircularProgress} from "./blocks/CenteredCircularProgress/CenteredCircularProgress";

export interface NoteProps extends ComponentProps<NoteProps> {
    title: string;
    note: string;
    postbackUrl: string;
}

export const NoteTitle = styled('div')`
    font-size: 18px;
    margin-bottom: 16px;
`

export const NoteActions = styled('div')`
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
`

export const NextButton = styled(Button)`
  font-weight: bold;
`

export const NoteBody = styled(TextField)`
    font-size: 13px;
    min-height: 50px;
    min-width: 450px;
  
    > .MuiInputBase-root {
      border-radius: 8px;
    }
`

export const Note: FC<NoteProps> = ({title, note: initialNote, postbackUrl, closeSection}) =>{
    const [note, setNote] = useState<string>(initialNote)
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true);
        await getApi().post(postbackUrl, {note})
        setLoading(false);
        closeSection()
    }

    return <div>
        <NoteTitle>{title}</NoteTitle>
        <NoteBody multiline value={note} onChange={e => setNote(e.target.value)}/>

        {loading ? <NoteActions>
            <CenteredCircularProgress />
        </NoteActions> :
            <NoteActions>
                <Button onClick={closeSection}>Cancel</Button>
                <NextButton disabled={note === initialNote} onClick={submit}>Next</NextButton>
            </NoteActions>
        }
    </div>
}