import {FC, useState} from "react";
import {ComponentProps} from "../engine/component-renderer/componentRenderer";
import {Button, MenuItem, Select, SelectChangeEvent, styled, TextField} from "@mui/material";
import {MuiGenericIcon} from "../utils/dynamic-icon";
import fromPairs from "lodash/fromPairs";
import {getApi} from "../services/api";
import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";
import {CenteredCircularProgress} from "./blocks/CenteredCircularProgress/CenteredCircularProgress";
import {reloadConversation} from "../redux/slices/conversation-slice";

//TODO Analyze a better typing structure here
type FormField = {
    "label": string,
    "id": string,
    "data": string,
    "type": "textbox"
}|{
    "label": string,
    "id": string,
    "type": "dropdown",
    "data": {
        "types": string[]
    }
}

export interface CreateContactProps extends ComponentProps<CreateContactProps> {
    title: string;
    icon: string;
    form: FormField[]
    postbackUrl: string;
}

export const Title = styled('div')`
    font-size: 18px;
    margin-bottom: 16px;
`

export const Actions = styled('div')`
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
`

export const Body = styled('div')`
    display: flex;
    align-items: flex-start;
    min-width: 450px;
`

export const Form = styled('form')`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 16px;
`

export const DropDown = styled(Select)`
    margin-top: 15px;
`

export const CTAButton = styled(Button)`
  font-weight: bold;
`

export const CreateContact: FC<CreateContactProps> = ({title, icon, form, postbackUrl, closeSection}) =>{
    const dispatch: AppDispatch = useDispatch()
    const [fieldsData, setFieldsData] = useState(fromPairs(form.map(f => [f.id, f.data])))
    const [loading, setLoading] = useState(false);

    const setFieldData = (fieldId: string) => (value: string) =>
        setFieldsData(allFields => ({...allFields, [fieldId]: value}))

    const submit = async () => {
        setLoading(true);
        await getApi().post(postbackUrl, fieldsData)
        dispatch(reloadConversation())
        setLoading(false);
        closeSection()        
    }

    return <div>
        <Title>{title}</Title>
        <Body>
            <MuiGenericIcon iconName={icon}/>
            <Form>
                {form.map(field =>
                    field.type === "textbox"?
                        <TextField id={field.id} variant="standard" value={fieldsData[field.id]} label={field.label}
                                   onChange={e => setFieldData(field.id)(e.target.value)}/>:
                        <DropDown
                            fullWidth
                            label={field.label}
                            variant="standard"
                            onChange={(event: SelectChangeEvent<any>) => setFieldData(field.id)(event.target.value)}
                        >{
                            field.data.types.map(t => <MenuItem value={t}>{t}</MenuItem>)
                        }</DropDown>
                )}
            </Form>
        </Body>

        {loading ? <Actions>
            <CenteredCircularProgress />
        </Actions> :
            <Actions>
                <Button onClick={closeSection}>Cancel</Button>
                <CTAButton onClick={submit}>Submit</CTAButton>
            </Actions>
        }
    </div>
}