import {Card, Chip, styled} from "@mui/material";
import TextField from '@mui/material/TextField';
import TuneIcon from '@mui/icons-material/Tune';
import {DRAWER_WIDTH} from "../../../engine/structural-sections/section-components/RightHandDrawer";

export const AgentPanelContainer = styled('div')<{drawerOpen?: boolean}>`
  padding: 25px 50px;
  width: calc(100% - 2 * 50px /*side padding*/ - 400px /*CCP*/ ${props => props.drawerOpen ? `- ${DRAWER_WIDTH}px` : ''});
`

export const ConversationId = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const PanelCard = styled(Card)`
  font-size: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;

`

//TODO Move this
export const CardSection = styled('div')`
  min-width: fit-content;
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
`

export const CardRowLabel = styled('span')`
    width: 75px;
`

export const CardRow = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
  position: relative;

  &:first-child {
    margin-top: 0
  }

  &:last-child {
    margin-bottom: 0
  }
`

export const StyledChip = styled(Chip)`
  margin-left: 12px;
  height: 28px;
  min-width: 240px;
  justify-content: flex-start;
`

export const ClientChipRoot = styled(StyledChip)<{baseColor: string}>`
  color: ${props => props.baseColor};
  border-color: ${props => props.baseColor};
`

export const ActionChip = styled(Chip)`
  margin-right: 12px;
  margin-bottom: 8px;
  height: 28px;
  cursor: pointer;
`

export const SearchBarInput = styled(TextField)`
  width: 100%;
  background: rgb(242, 242, 242);
  border-radius: 20px;

  & .MuiOutlinedInput-root fieldset {
    border: none;
  }
  
  & .MuiInputBase-input {
    padding: 8px;
  }
`

export const ClickableTuneIcon = styled(TuneIcon)<{ background?: string }>`
  cursor: pointer;
  background: ${props => props.background ? props.background : 'transparent'};
  border-radius: 20px;
  padding: 3px;
`

export const AccountResultsTitle = styled('div')`
  padding: 10px 0;
`

export const AccountCardContainer = styled(Card)`
  padding: 15px;
`