import {AppDispatch} from "../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {closeSection, showSection, StructuralSection} from "./structural-sections/structuralSectionsFactory";
import {renderComponentTree} from "./component-renderer/componentRenderer";

export const useSection = (section: StructuralSection) => {
    const content = useSelector(section.selectors.getContent)
    const isOpen = useSelector(section.selectors.isOpen)
    const isLoading = useSelector(section.selectors.isLoading)
    const dispatch: AppDispatch = useDispatch()
    const close = () => dispatch(closeSection(section))
    const show = () => dispatch(showSection(section))
    const renderContent = (extraProps?: any) => renderComponentTree(section)({...content, ...extraProps})

    return {
        content,
        isOpen,
        isLoading,
        close,
        show,
        renderContent
    }
}