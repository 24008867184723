import {ComponentProps} from "../../engine/component-renderer/componentRenderer";
import {TabbedQuickActionContent} from "../TabbedQuickActions";
import {ServiceDetailData, Record} from "../ServiceDetail";
import {NavActionData} from "../../engine/navigation/useNavAction";
import {FC, useState} from "react";
import {Card, Chip, styled} from "@mui/material";
import {ExpandMoreToggle} from "../IconDescriptionExpansionCard/IconDescriptionExpansionCard.styles";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { DateTime } from "luxon";
import {MuiGenericIcon} from "../../utils/dynamic-icon";

export interface TicketExpansionCardProps  extends ComponentProps<TicketExpansionCardProps> {
    date:    string;
    header:  Header;
    rows:    Record[];
    details: ServiceDetailData;
    action:  NavActionData;
}


export interface Header {
    type:        string;
    statusLabel: string;
    status:      string;
    color:       string;
}

export const TicketExpansionCardRoot =  styled(Card)`
    padding-top:16px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
`

export const TicketExpansionCardHeader = styled('div')<{background?: string}>((props) => `
    background: ${props.background? props.background : props.theme.palette.primary.main};
    color: white;
    padding: 12px;
    display: flex;
    justify-content: space-between;
`)


export const TicketExpansionCardContent=  styled('div')`
  color: rgba(0, 0, 0, 0.6);
  padding: 12px;
`

export const TicketExpansionUnexpandedContent=  styled('div')<{collapsed?: boolean, margin?:string}>`
  height: ${props => props.collapsed? 0 : 'fit-content'};
  visibility: ${props => props.collapsed? 'hidden' : 'visible'};
  display: flex;
  justify-content: space-between;
  margin: ${props => props.collapsed? 0 : (props.margin? props.margin : '4px')} 0;
`

export const TicketExpansionStatusLabel=  styled('span')`
    font-weight: 700;
    margin-right: 8px;
`

export const ExpandMoreToggleContainer = styled('div')`
  margin-right: 8px;
  cursor: pointer;
  font-size: 14px;
`

export const UpdateTicketButton = styled(Chip)`
  background: rgb(238, 236, 242);
  margin-top: 8px;
  cursor: pointer;
`

export const TicketExpansionCard: FC<TicketExpansionCardProps> = ({header, rows, details, action, renderChild}) => {
    const [expanded, setExpanded] =  useState(false)

    return <TicketExpansionCardRoot variant='outlined'>
        <TicketExpansionCardHeader>
            {header.type}
            <div>
                <TicketExpansionStatusLabel>{header.statusLabel}</TicketExpansionStatusLabel>
                <span>{header.status}</span>
            </div>
        </TicketExpansionCardHeader>
        <TicketExpansionCardContent>
            <TicketExpansionUnexpandedContent>
                <div>
                    {rows.map(r => <>
                        <span style={{marginRight: 24}}>{r.description}</span>
                        {r.value}
                    </>)}
                </div>
                <ExpandMoreToggleContainer>
                    <ExpandMoreToggle scale={1.8} toggled={expanded} onClick={() => setExpanded(e => !e)}/>
                </ExpandMoreToggleContainer>
            </TicketExpansionUnexpandedContent>
            <TicketExpansionUnexpandedContent collapsed={!expanded} margin={'8px'}>
                {renderChild(details)}
            </TicketExpansionUnexpandedContent>
            <TicketExpansionUnexpandedContent>
                <UpdateTicketButton label={action.label} icon={<MuiGenericIcon iconName={action.icon}/>}/>
            </TicketExpansionUnexpandedContent>
        </TicketExpansionCardContent>
    </TicketExpansionCardRoot>
}