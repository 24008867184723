import {useDispatch, useSelector} from "react-redux";
import {addGlobalLinks as addGlobalLinksAction, getGlobalLinks} from "../redux/slices/nav-slice";
import {AppDispatch} from "../redux/store";

export const useGlobalLinks = () => {
    const dispatch: AppDispatch = useDispatch()
    const globalLinks = useSelector(getGlobalLinks)

    const getGlobalLink = (rel: string) => globalLinks[rel]

    const addGlobalLink = (link: string) => dispatch(addGlobalLinksAction([link]))

    const addGlobalLinks = (links: string[]) => dispatch(addGlobalLinksAction(links))

    return {
        globalLinks, getGlobalLink, addGlobalLink, addGlobalLinks
    }
}