import {FC} from "react";
import {SectionTitle} from "../blocks/shared.styles";
import {AccountExpansionCardData} from "../AccountExpansionCard";
import {ComponentData, ComponentProps, renderComponent} from "../../engine/component-renderer/componentRenderer";

export interface PatientListData extends ComponentProps<PatientListData> {
    title: string;
    cards: AccountExpansionCardData[];
}

export const PatientResults: FC<PatientListData> = ({title, cards, renderChild}) => {
    return <>
        <SectionTitle>{title}</SectionTitle>
        {cards.map(renderChild)}
    </>
}