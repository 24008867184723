import {styled} from "@mui/material";
import {FC, useState} from "react";
import {SearchBarInput} from "../pages/Home/AgentPanel/styles";
import SearchIcon from "@mui/icons-material/Search";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

const SearchableCardContainerRoot = styled('div')`
  margin-top: 16px;
`

const SearchableCards = styled('div')`
  > * {
    margin-top: 8px;
  }
`

export interface SearchableCardContainerData extends ComponentProps<SearchableCardContainerData> {
    searchbar: {
        "icon": string,
        "placeholder": string,
        "searchable": string[]
    },
    cards: ComponentData[]
}

export const SearchableCardContainer: FC<SearchableCardContainerData> = ({searchbar: {icon, placeholder}, cards, renderChild}) => {
    const [searchTerm, setSearchTerm] = useState('');
    return <SearchableCardContainerRoot>
        <SearchBarInput variant="outlined" InputProps={{
            placeholder,
            value: searchTerm,
            onChange: e => setSearchTerm(e.target.value),
            startAdornment: <SearchIcon/>
        }}/>
        <SearchableCards>
            {cards.filter(c => !searchTerm || JSON.stringify(c).toLowerCase().includes(searchTerm.toLowerCase())).map(renderChild)}
        </SearchableCards>
    </SearchableCardContainerRoot>
}