import {FC} from "react";
import {
    SectionTitle,
    ServiceDetailRecordContainer,
    ServiceDetailRoot,
    ServiceDetailTitle
} from "./blocks/shared.styles";
import {ComponentProps} from "../engine/component-renderer/componentRenderer";

export interface DescriptionRowsContainerData extends ComponentProps<DescriptionRowsContainerData> {
    "title": string,
    "rows": [
        {
            "header": string,
            "descriptions": string[]
        }
    ]
}

export const DescriptionRowsContainer: FC<DescriptionRowsContainerData> = ({title, rows}) => {
    return <>
        {title && <SectionTitle>{title}</SectionTitle>}
        {rows.map(({header, descriptions}) =>
            <ServiceDetailRoot>
                <ServiceDetailTitle>{header}</ServiceDetailTitle>
                <>{
                    descriptions.map(d => <ServiceDetailRecordContainer>{d}</ServiceDetailRecordContainer>)
                }</>
            </ServiceDetailRoot>)}
    </>
}