import {FC} from "react";
import {ContentSection} from "./blocks/shared.styles";
import {styled} from "@mui/material";


import {ComponentData} from "../engine/component-renderer/componentRenderer";

export const DetailGroup = styled('div')`
  margin-top: 16px;
`

export interface GroupedValueCardData extends ComponentData {
    key?: string;
    values: string[][]
}

export const GroupedValueCard: FC<GroupedValueCardData> = ({values}) => {
    return <>
        <ContentSection>
            {values.map(valueGroup =>
                <DetailGroup>{valueGroup.map(line => <div>{line}</div>)}</DetailGroup>
            )}
        </ContentSection>
    </>
}