import {FC, useState} from "react";
import {ExpandIcon, ExpansionDetailsContainer, SectionTitle} from "./blocks/shared.styles";
import {styled} from "@mui/material";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export const ExpansionPanelRoot = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export interface ExpansionPanelData extends ComponentProps<ExpansionPanelData> {
    title: string;
    expanded: boolean;
    summary: string;
    details: ComponentData
}

export const ExpansionPanel: FC<ExpansionPanelData> = ({title,expanded, summary, details, renderChild}) => {
    const [open, setOpen] = useState(expanded)
    return <>
        <ExpansionPanelRoot>
            <SectionTitle>{title}</SectionTitle>
            {summary && <div>{summary}</div>}
            <ExpandIcon open={open} onClick={() => setOpen(o => !o)}/>
        </ExpansionPanelRoot>
        <ExpansionDetailsContainer open={open}>
            {renderChild(details)}
        </ExpansionDetailsContainer>
    </>
}