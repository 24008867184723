import {FC} from "react";
import {SectionTitle} from "./blocks/shared.styles";
import {styled} from "@mui/material";
import {ComponentData, ComponentProps, renderComponent} from "../engine/component-renderer/componentRenderer";

export const ServiceDetailContainerRoot = styled('div')`
  padding: 0 16px 16px 16px;
`

export interface ServiceDetailContainerData extends ComponentProps<ServiceDetailContainerData> {
    title?: string,
    serviceDetail: ComponentData[]
}

export const ServiceDetailContainer: FC<ServiceDetailContainerData> = ({title, serviceDetail, renderChild}) => {
    return <ServiceDetailContainerRoot>
        {title && <SectionTitle>{title}</SectionTitle>}
        <>{serviceDetail.map(renderChild)}</>
    </ServiceDetailContainerRoot>
}