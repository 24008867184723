import {FC, useState} from "react";
import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import {MuiGenericIcon} from "../../../utils/dynamic-icon";
import {range} from "lodash";
import {DateTime} from "luxon";
import {StepNavigation, StepNavProps} from "../StepNavigation";
import {navigate, sendCardData, StepperAction} from "../../../redux/slices/stepper-slice";
import {AppDispatch} from "../../../redux/store";
import {useDispatch} from "react-redux";
import {CardFormRoot} from "./PaymentMethodField";

export const CardForm: FC<StepNavProps> = ({nextLabel, backLabel, onClose}) => {
    const dispatch: AppDispatch = useDispatch()
    const [number, setNumber] = useState('')
    const [name, setName] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [cvv, setCvv] = useState('')
    const [zipCode, setZipCode] = useState('')

    const navigateForward = () => dispatch(sendCardData({number, name, month, year, cvv, zipCode}))

    const navigateBack = () => dispatch(navigate(StepperAction.BACK, null, onClose))

    return <>
        <CardFormRoot>
            <TextField variant={"standard"} placeholder={"Name on Card"} name="nameoncard" fullWidth value={name}
                       onChange={e => setName(e.target.value)}
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><MuiGenericIcon
                               iconName={"AccountBox"}/></InputAdornment>,
                       }}
            />
            <TextField variant={"standard"} placeholder={"Card Number"} name="cardnumber" fullWidth value={number}
                       onChange={e => setNumber(e.target.value)}
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><MuiGenericIcon
                               iconName={"CreditCard"}/></InputAdornment>,
                       }}
            />
            <div>
                <Autocomplete
                    disablePortal
                    options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
                    inputValue={month}
                    onInputChange={(_, value) => setMonth(value)}
                    renderInput={(params) => <TextField {...params} placeholder="Exp. MM" variant="standard"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: <InputAdornment position="start"><MuiGenericIcon
                                                                iconName={"CalendarToday"}/></InputAdornment>,
                                                        }}/>}
                />
                <Autocomplete
                    disablePortal
                    options={range(DateTime.now().year, DateTime.now().year + 11).map(y => String(y))}
                    inputValue={year}
                    onInputChange={(_, value) => setYear(value)}
                    renderInput={(params) => <TextField {...params} placeholder="YYYY" variant="standard" InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment position="start"><MuiGenericIcon
                            iconName={"CalendarToday"}/></InputAdornment>,
                    }}/>}
                />
            </div>
            <div>
                <TextField variant={"standard"} placeholder={"CVV"} fullWidth value={cvv}
                           onChange={e => setCvv(e.target.value)}
                           InputProps={{
                               startAdornment: <InputAdornment position="start"><MuiGenericIcon
                                   iconName={"Lock"}/></InputAdornment>,
                           }}
                />
                <TextField variant={"standard"} placeholder={"Zip Code"} fullWidth value={zipCode}
                           onChange={e => setZipCode(e.target.value)}
                           InputProps={{
                               startAdornment: <InputAdornment position="start"><MuiGenericIcon
                                   iconName={"LocationOn"}/></InputAdornment>,
                           }}
                />
            </div>
        </CardFormRoot>
        <StepNavigation nextLabel={nextLabel} backLabel={backLabel} navigateForward={navigateForward}
                        navigateBack={navigateBack}/>
    </>
}