import flex from '@cybersource/flex-sdk-web'
import { CYBERSOURCE_TEST_MODE } from "../config"
import * as cardValidator from 'card-validator'

export type CyberSourceKey = {
  kid?: string
  keystore: any
}

export type CyberSourcePaymentMethod = {
  cardNumber: string
  securityCode: string
  cardType: string
  expiryMonth: string
  expiryYear: string
}

export type CyberSourceTokenizedPaymentMethod = {
  keyId: string
  token: any
  maskedPan: string
  cardType: string
  timestamp: number
  signedFields: string
  signature: string
}

export const getCardType = (cardNumber: string) => {
  const numberValidation = cardValidator.number(cardNumber)
  let cardType;

  switch (numberValidation?.card?.type) { //TODO make more declarative
      case 'visa':
        cardType = '001'
        break;
      case 'mastercard':
        cardType = '002'
        break;
      case 'american-express':
        cardType = '003'
        break;
      case 'discover':
        cardType = '004'
        break;
      default:
        break;
  }
  return cardType;
}

export const getCardDescription = (cardNumber: string) => {
  const numberValidation = cardValidator.number(cardNumber)
  let description;

  switch (numberValidation?.card?.type) { //TODO make more declarative
    case 'visa':
      description = 'Visa'
      break;
    case 'mastercard':
      description = 'MasterCard'
      break;
    case 'american-express':
      description = 'American Express'
      break;
    case 'discover':
      description = 'Discover'
      break;
    default:
      description = 'Credit/Debit Card'
      break;
  }
  return description;
}




export class CyberSourceClient {
  static async tokenizePaymentMethod(key: CyberSourceKey, data: CyberSourcePaymentMethod): Promise<CyberSourceTokenizedPaymentMethod>{
    const options = {
      ...key,
      cardInfo: data,
      encryptionType: 'rsaoaep256',
      production: CYBERSOURCE_TEST_MODE,
    };

    return new Promise<CyberSourceTokenizedPaymentMethod>((resolve, reject) => {
      flex.createToken(options, (response: any) => {
        if (response.error) {
          reject(response.error)
        } else {
          resolve(response)
        }
      })
    })
  }
}
